import {defineStore} from 'pinia'
import {file} from "paths.macro";
import {useDeviceStore} from "@/stores/device";
import {useCatalogsStore} from "@/stores/catalogs";
import {CURRENT_PRODUCT} from "@/utils/build-config";
import {myAxiosRetry} from "@/services/myAxios";
import {getTotalPrice} from "@/helpers/api";
import {eventBus} from "@/main";
import {useMomentaryStore} from "@/stores/momentary";

export const useCartStore = defineStore('cart', {
    state: () => ({
        cart: [],
        adpCart: [],
        enpCart: [],
        cellPrices: [],
        totalPrice: {
            price: 0,
            NET: 0,
            ADD: 0,
            EURO: 0,
            RUB: 0,
            agreementRUB: 0,
            agreementUSD: 0,
            agreementEURO_RU: 0,
            priceDisplay: 0,
            priceGP: 0,
        },
        priceRequestId: 0
    }),
    getters: {
        getCart: (state) => state.cart,
        getADPCart: (state) => state.adpCart,
        getENPCart: (state) => state.enpCart,
        getCartLength: (state) => state.cart.length,
        getADPCartLength: (state) => state.adpCart.length,
        getENPCartLength: (state) => state.enpCart.length,
        getCellPrice: (state) => {
            return (id) => {
                let deviceStore = useDeviceStore()
                let currency = deviceStore.getAgreementCurrency

                let cellPrice = '?'
                let objIndex = state.cellPrices.findIndex((obj => obj.id === id));

                if (objIndex !== -1) {
                    if (currency === 'USD')
                        cellPrice = state.cellPrices[objIndex].priceAgreementUsd

                    if (currency === 'RUB')
                        cellPrice = state.cellPrices[objIndex].priceAgreementRub
                }

                return cellPrice
            }
        },

        getTotalPrice: (state) => {
            let deviceStore = useDeviceStore()
            let currency = deviceStore.getAgreementCurrency

            if (currency === 'USD')
                return state.totalPrice.agreementUSD
            else if (currency === 'RUB')
                return state.totalPrice.agreementRUB
            else
                return undefined
        }
    },
    actions: {
        clear() {
            this.cart = []
            this.adpCart = []
            this.enpCart = []
            this.cellPrices = []
            this.clearTotalPrice()
        },
        clearTotalPrice() {
            this.totalPrice.price = 0
            this.totalPrice.NET = 0
            this.totalPrice.ADD = 0
            this.totalPrice.EURO = 0
            this.totalPrice.RUB = 0
            this.totalPrice.agreementRUB = 0
            this.totalPrice.agreementUSD = 0
            this.totalPrice.agreementEURO_RU = 0
            this.totalPrice.priceDisplay = 0
            this.totalPrice.priceGP = 0
        },
        addAllPublications() {
            let catalogsStore = useCatalogsStore()
            let momentaryStore = useMomentaryStore()

            let adpByRoute = momentaryStore.route.adpbyroute
            let enpByRoute = momentaryStore.route.enpbyroute

            catalogsStore.getENP.products.forEach(item => {

                let disabled = true
                if (enpByRoute.find((a) => a.id === item.id)) {
                  disabled = false
                }

                this.enpCart.push({id: item.id, name: item.title, disabled: disabled, periods: [3]})
            })
            catalogsStore.getADP1.products.forEach(item => {
                let disabled = true
                if (adpByRoute.find((a) => a.id === item.id)) {
                    disabled = false
                }

                this.adpCart.push({id: item.id, name: item.title, disabled: disabled, periods: [3]})
            })
            catalogsStore.getADP2.products.forEach(item => {
                let disabled = true
                if (adpByRoute.find((a) => a.id === item.id)) {
                    disabled = false
                }

                this.adpCart.push({id: item.id, name: item.title, disabled: disabled, periods: [3]})
            })
            catalogsStore.getADP3.products.forEach(item => {
                let disabled = true
                if (adpByRoute.find((a) => a.id === item.id)) {
                    disabled = false
                }

                this.adpCart.push({id: item.id, name: item.title, disabled: disabled, periods: [3]})
            })

        },
        addCells(keysList) {
            let catalogStore = useCatalogsStore()

            return new Promise(resolve => {
                const allItems = catalogStore.getProducts;
                let newItems = [];

                keysList.forEach(key => {
                    let item = allItems.find(obj => {
                        return obj.id == key;
                    })
                    if (!item)
                        console.log(key)
                    if (!newItems.find(obj => {
                        return obj.id === key;
                    }))
                        newItems.push(item);
                })

                let CartItems = [];
                newItems.forEach(item => {
                    const countryInfo = catalogStore.getCountries.find(x => x.id === item.countryId);
                    let subTypes
                    if (countryInfo) {
                        subTypes = countryInfo.possibleSubscriptionTypeList.possibleSubscriptionTypes
                    } else {
                        //TODO
                        subTypes = [{"id": 4}];
                    }

                    let periods = []
                    subTypes.forEach(x => {
                        switch (x.id) {
                            case 0:
                                periods.push(12);
                                break;
                            case 1:
                                periods.push(6);
                                break;
                            case 4:
                                periods.push(3);
                                break;
                            default:
                                break;
                        }
                    })
                    CartItems.push(
                        {
                            id: item.id,
                            name: item.title,
                            cells: (item.id.startsWith("102") ? item.productDatasetList.productDatasets : item.productCellList.productCells),
                            periods: periods,
                            disabled: false
                        }
                    )
                })
                this.cart = CartItems;
                resolve()
            })
        },

        updateCell(payload) {
            let objIndex = this.cart.findIndex((obj => obj.id === payload.id));
            if (payload.type === 'switch')
                this.cart[objIndex].disabled = ! this.cart[objIndex].disabled

            if (payload.type === 'on')
                this.cart[objIndex].disabled = false

            if (payload.type === 'off')
                this.cart[objIndex].disabled = true

            return this.cart[objIndex].disabled
        },

        updateENP(payload) {
            let objIndex = this.cart.findIndex((obj => obj.id === payload.id));
            if (payload.type === 'switch')
                this.cart[objIndex].disabled = ! this.cart[objIndex].disabled

            if (payload.type === 'on')
                this.cart[objIndex].disabled = false

            if (payload.type === 'off')
                this.cart[objIndex].disabled = true

            return this.cart[objIndex].disabled
        },

        calculatePrice({products, periodBand, agreementID, deviceID, appType, isManualCalculating = true}) {
            let deviceStore = useDeviceStore()
            let provider = deviceStore.getAgreementProvider
            let requestId = this.priceRequestId++

            return new Promise(resolve => {

                if (products.length > 0 && periodBand) {
                    let items = [];
                    products.forEach(x => {
                        if (!x.disabled)
                            items.push(x.id)
                    })

                    let quotate = {}

                    if (provider === 'UKHO') {
                        let months = 3
                        if (periodBand === '1')
                            months = 6
                        if (periodBand === '0')
                            months = 12

                        quotate = {
                            "cells": items,
                            "monthCount": months
                        }
                    }

                    if (provider === 'Primar') {
                        quotate = {
                            id: 1234,
                            items: items,
                            subscriptionType: periodBand,
                            agreementId: agreementID,
                            deviceId: deviceID,
                            applicationTypeId: appType,
                            quotateManually: isManualCalculating
                        };
                    }

                    // let URL = "/api/v1/orders/primar/quotate/v3"
                    // if (provider === 'UKHO') {
                    //     URL = "api/v1/mss/avcquotate"
                    // }

                    // myAxiosRetry.post(URL, quotate).then(resp => {
                    getTotalPrice(provider, quotate, requestId).then(resp => {
                        console.log("aaaa AAAA1", resp)
                        let usdPrice = 0
                        if (provider === 'UKHO') {
                            resp.data.uoses.forEach(item => {
                                usdPrice += item.price
                            })

                            this.clearTotalPrice()
                            this.totalPrice.agreementUSD = Number(usdPrice).toFixed(2)
                            this.totalPrice.displayPrice = Number(usdPrice).toFixed(2)

                            this.cellPrices = []
                            resp.data.uoses.forEach(item => {
                                let cellPriceEuro = 0
                                let cellPriceRub = 0
                                let cellPriceAgreementUsd = Number(Number(item.price).toFixed(2))
                                let cellPriceAgreementRub = 0
                                this.cellPrices.push({id: item.name, priceEuro: cellPriceEuro, priceRub: cellPriceRub, priceAgreementUsd: cellPriceAgreementUsd, priceAgreementRub: cellPriceAgreementRub})
                            })
                        }

                        if (provider === 'Primar') {
                            this.clearTotalPrice()
                            this.totalPrice.EURO = Number(Number(resp.data.userEuro).toFixed(2))
                            this.totalPrice.RUB = Number(Number(resp.data.userRub).toFixed(2))
                            this.totalPrice.ADD = Number(Number(resp.data.userEuroAdd).toFixed(2))
                            this.totalPrice.NET = Number(Number(resp.data.euroPrice).toFixed(2))
                            this.totalPrice.agreementUSD = Number(Number(resp.data.agreementUsd).toFixed(2))
                            this.totalPrice.agreementRUB = Number(Number(resp.data.agreementRub).toFixed(2))
                            this.totalPrice.displayPrice = Number(Number(resp.data.displayPrice).toFixed(2))

                            this.cellPrices = []
                            resp.data.itemsPrice.forEach(item => {
                                let cellPriceEuro = Number(Number(item.userEuro).toFixed(2))
                                let cellPriceRub = Number(Number(item.userRub).toFixed(2))
                                let cellPriceAgreementUsd = Number(Number(item.agreementUsd).toFixed(2))
                                let cellPriceAgreementRub = Number(Number(item.agreementRub).toFixed(2))
                                this.cellPrices.push({id: item.id, priceEuro: cellPriceEuro, priceRub: cellPriceRub, priceAgreementUsd: cellPriceAgreementUsd, priceAgreementRub: cellPriceAgreementRub})
                            })
                        }
                        resolve(this.totalPrice.price);
                    }).catch(err => {
                        console.log("aaaa AAAA2", err)
                        eventBus.$emit('recalculate-total-price')
                    })
                } else {
                   this.clearTotalPrice()
                }
            })
        },
        reset() {
        }
    },
    persist: false
})
