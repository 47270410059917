import {defineStore} from 'pinia'
import dayjs from "dayjs";
import {useCatalogsStore} from "@/stores/catalogs";

export const useMomentaryStore = defineStore('momentary', {
    state: () => ({
        isBusy: false,
        isAPIError: false,
        isOrdersBusy: false,
        route: {
            chartsbyroute: [],
            adpbyroute: [],
            enpbyroute: [],
            chartsbylegs: [],
            pubsbylegs: []
        },
    }),
    actions: {
        setBusy(flag) {
            this.isBusy = flag
        },
        setAPIError(flag) {
            this.isAPIError = flag
        },
        setOrdersBusy(flag) {
            this.isOrdersBusy = flag
        },
        addChartsByRoute(val) {
            this.route.chartsbyroute = val
        },
        addADPByRoute(val) {
            val["1"].forEach(item => {
                this.route.adpbyroute.push({id: item, disabled: false})
            })
        },
        addENPByRoute(val) {
            val["1"].forEach(item => {
                this.route.enpbyroute.push({id: item, disabled: false})
            })
        },
        // addAllPublications() {
        //     let catalogsStore = useCatalogsStore()
        //
        //     catalogsStore.getENP.products.forEach(item => {
        //         this.route.enpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        //     catalogsStore.getADP1.products.forEach(item => {
        //         this.route.adpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        //     catalogsStore.getADP2.products.forEach(item => {
        //         this.route.adpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        //     catalogsStore.getADP3.products.forEach(item => {
        //         this.route.adpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        // },
        addChartsByLegs(val) {
            this.route.chartsbylegs = val
        },
        addPubsByLegs(val) {
            this.route.pubsbylegs = val
        },
        clearChartsByRoute() {
            this.route.chartsbyroute = []
        },
        clearChartsByLegs() {
            this.route.chartsbylegs = []
        },
    },
    persist: false
})
