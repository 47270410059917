<template>
  <div>

<!--    <pre class="m-0">{{ form }}</pre>-->

    <b-container>

    <b-form size="sm" @submit="registerEcdis" v-if="show">
      <b-form-group size="sm" label="Emails:" label-for="input-1">
        <b-form-input size="sm" id="input-1" v-model="form.email" type="text" :state="emailsState" placeholder="" required></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Ship name:" label-for="input-2">
        <b-form-input id="input-2" v-model="form.ship_name" type="text" placeholder="" required></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="IMO:" label-for="input-3">
        <b-form-input id="input-3" v-model="form.ship_imo" type="text" :state="imoState" placeholder="" required></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Permit:" label-for="input-9">
        <b-form-input id="input-9" v-model="form.permit_main" type="text" placeholder="" required></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Country:" label-for="input-14">
        <b-form-select id="input-14" v-model="form.country" :options="country" required></b-form-select>
      </b-form-group>
      <b-form-group size="sm" label="MMSI:" label-for="input-4">
        <b-form-input id="input-4" v-model="form.ship_mmsi" type="text" :state="mmsiState" placeholder="(optional)"></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Call Sign:" label-for="input-8">
        <b-form-input id="input-8" v-model="form.ship_callsign" type="text" :state="callsignState" placeholder="(optional)"></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Ship category:" label-for="input-5">
        <b-form-select id="input-5" v-model="form.ship_category" :options="categories"></b-form-select>
      </b-form-group>
      <b-form-group size="sm" label="ECDIS brand:" label-for="input-6">
        <b-form-select id="input-6" v-model="form.ecdis_brand" :options="brands"></b-form-select>
      </b-form-group>
      <b-form-group size="sm" label="ECIDS OS:" label-for="input-7">
        <b-form-select id="input-7" v-model="form.ecdis_os" :options="systems"></b-form-select>
      </b-form-group>
      <b-form-group size="sm" label="Backup permit 1:" label-for="input-10">
        <b-form-input id="input-10" v-model="form.permits_backup[0]" type="text" placeholder="(optional)"></b-form-input>
      </b-form-group>
      <b-form-group size="sm" label="Backup permit 2:" label-for="input-11">
        <b-form-input id="input-11" v-model="form.permits_backup[1]" type="text" placeholder="(optional)"></b-form-input>
      </b-form-group>

      <div v-if="$store.state.current.ecdis.edit">
        <b-button type="button" @click="registerEcdis" variant="primary">Save</b-button>&nbsp;
      </div>
      <div v-else>
        <b-button type="button" @click="registerEcdis" variant="primary">Add</b-button>&nbsp;
      </div>
      <br>
<!--      <b-button type="reset" variant="danger">Reset</b-button>-->

    </b-form>

    <b-modal v-model="modalShow_ok" title="ECDIS registration" ok-only @ok="gotoMyDevices">
      <p>
        Now you can visit the <a href="./devices" v-b-tooltip title="My Devices">My Devices</a> tab and select the ECDIS device to purchase charts.
      </p>
    </b-modal>

    </b-container>

  </div>
</template>

<script>

import {myAxios} from "@/services/myAxios";
import {eventBus} from "@/main";
import router from "@/router";

export default {
  components: {},
  props: {
    value: Array,
    fields: Array
  },
  data() {
    return {

      form: {
        email: '',
        ship_name: '',
        ship_imo: '',
        ship_mmsi: '',
        ship_callsign: '',
        ship_category: '',
        country: '',

        ecdis_brand: '',
        ecdis_os: '',
        ecdis_type: '',

        permit_main: '',
        permits_backup: ['',''],
      },
      categories: [{ text: '(optional)', value: '' },
        'BRGE Barge',
        'WMS WMS',
        'CAR Vehicle Carrier',
        'COMB Combination',
        'CONT Container',
        'DRDG Dredger',
        'ICE Icebreaker',
        'NOT Not a Vessel',
        'NVY Naval vessel',
        'PASS Passenger Vessel',
        'PILOT Pilot',
        'RES Research Vessel',
        'RFR Reefer',
        'RORO Ro-Ro',
        'SLV Salvage Vessel',
        'SRV Survey Vessel',
        'TUG Tug',
        'VTS Vessel Traffic System',
        'CRG Dry Cargo/Passenger',
        'FSH Fishing',
        'BULK Bulk Carriers',
        'OFS Offshore',
        'OTH Miscellaneous',
        'TNK Tankers',
        'INLW Inland Watwerways',
        'NONM Non-Merchant Ships',
        'NONP Non Propelled',
        'NSS Non Ship Structures'],

      country: [{text: '', value: '' },
        'AL Albania',
        'DZ Algeria',
        'AO Angola',
        'AG Antigua and Barbuda',
        'AR Argentina',
        'AW Aruba',
        'AU Australia',
        'AT Austria',
        'BS Bahamas',
        'BH Bahrain',
        'BD Bangladesh',
        'BB Barbados',
        'BE Belgium',
        'B1 Belgium',
        'BZ Belize',
        'BJ Benin',
        'BM Bermuda',
        'BO Bolivia',
        'BR Brazil',
        'B2 Brunei Darussalam',
        'BN Brunei Darussalam',
        'BG Bulgaria',
        'KH Cambodia',
        'CM Cameroon',
        'CA Canada',
        'CV Cape Verde',
        'CL Chile',
        'C1 China C1',
        'C2 China C2',
        'CN China CN',
        'CO Colombia',
        'KM Comoros',
        'CD Congo (Dem. Rep. Of)',
        'CG Congo(Rep. of)',
        'CK Cook Islands',
        'CR Costa-Rica',
        'CI Côte d’Ivoire',
        'HR Croatia',
        'CU Cuba',
        'CY Cyprus',
        'CZ Czech Republic',
        'DK Denmark',
        'D1 Denmark',
        'DJ Djibouti',
        'DM Dominica',
        'DO Dominican Rep.',
        'EC Ecuador',
        'EG Egypt',
        'SV El Salvador',
        'GQ Equatorial Guinea',
        'ER Eritrea',
        'EE Estonia',
        'ET Ethiopia',
        'FO Faeroeyene',
        'FJ Fiji',
        'FI Finland',
        'FR France',
        'GA Gabon',
        'GM Gambia',
        'GE Georgia',
        'DE Germany',
        'GH Ghana',
        'GR Greece',
        'GD Grenada',
        'GT Guatemala',
        'G1 Guatemala',
        'GN Guinea',
        'GW Guinea-Bissau',
        'GY Guyana',
        'HT Haiti',
        'HN Honduras',
        'IS Iceland',
        'IN India',
        'ID Indonesia',
        'IR Iran',
        'IQ Iraq',
        'IE Ireland',
        'IL Israel',
        'IT Italy',
        'JM Jamaica',
        'JP Japan',
        'JO Jordan',
        'KE Kenya',
        'KI Kiribati',
        'KP Korea (DPR of)',
        'KR Korea (Rep. of)',
        'KW Kuwait',
        'LV Latvia',
        'LB Lebanon',
        'LR Liberia',
        'LY Libyan Arab Jamahiriya',
        'LT Lithuania',
        'LU Luxembourg',
        'MG Madagascar',
        'MW Malawi',
        'MY Malaysia',
        'MV Maldives',
        'MT Malta',
        'MH Marshall Islands',
        'MR Mauritania',
        'MU Mauritius',
        'MX Mexico',
        'FM Micronesia(Federated States of)',
        'MC Monaco',
        'MN Mongolia',
        'ME Montenegro',
        'MA Morocco ',
        'MZ Mozambique',
        'MM Myanmar',
        'NA Namibia',
        'NR Nauru',
        'NL Netherlands',
        'NZ New Zealand',
        'NI Nicaragua',
        'NG Nigeria',
        'NU Niue ',
        'NO Norway',
        'N1 Norway',
        'OM Oman',
        'PK Pakistan',
        'PW Palau',
        'PA Panama',
        'PG Papua New Guinea',
        'PY Paraguay',
        'PE Peru',
        'PH Philippines',
        'PL Poland',
        'PT Portugal',
        'QA Qatar',
        'RO Romania ',
        'RU Russian Federation',
        'KN Saint Kitts and Nevis',
        'LC Saint Lucia',
        'VC Saint Vincent and the Grenadines',
        'WS Samoa',
        'ST Sao Tome and Principe',
        'SA Saudi Arabia',
        'SN Senegal',
        'SC Seychelles',
        'SL Sierra Leone',
        'SG Singapore',
        'SI Slovenia',
        'SB Solomon Islands',
        'SO Somalia',
        'ZA South Africa(Rep. of)',
        'ES Spain',
        'LK Sri Lanka',
        'SD Sudan',
        'SR Suriname',
        'SE Sweden',
        'CH Switzerland',
        'SY Syrian Arab Republic',
        '1U Taiwan',
        'TZ Tanzania',
        'TH Thailand',
        'TG Togo',
        'TK Tokelau',
        'TO Tonga',
        'TT Trinidad&Tobago',
        'TN Tunisia',
        'TR Turkey',
        'TV Tuvalu',
        'UA Ukraine',
        'AE United Arab Emirates',
        'GB United Kingdom',
        'NN Unknown',
        'UY Uruguay',
        'U1 USA',
        'U2 USA',
        'US USA',
        'VU Vanuatu',
        'VE Venezuela',
        'VN Viet Nam',
        'YE Yemen',
        'YU Yugoslavia',
        'ZZ Zanzibar'],

      brands: [{ text: '(optional)', value: '' },
        'dKart',
        'FURUNO',
        'GEM Elettronica',
        'GMSTECH',
        'Highlander',
        'Kelvin Hughes',
        'Kongsberg Maritime',
        'Maris',
        'mKart MEGA ECDIS',
        'NAUDEQ',
        'Navis USA LLC',
        'NaviSailor',
        'PC Maritime',
        'Raytheon Anschütz',
        'Seatronx',
        'Sodena',
        'Sperry Marine',
        'TOKIO KEIKI',
        'Transas',
        'JRC',
        'Unknown'],

      systems: [{ text: '(optional)', value: '' },
        'Windows',
        'Linux',
        'Android',],

      types: [{ text: '(optional)', value: '' },
        'Main',
        'Backup',],

      show: true,

      modalShow_ok: false,
      modalShow_nok: false,
    }
  },
  mounted() {

    if (this.$store.state.current.ecdis.edit) {
      this.form.permit_main = this.$store.state.current.ecdis.permit_main;
      this.form.permits_backup[0] = this.$store.state.current.ecdis.permits_backup.at(0);
      this.form.permits_backup[1] = this.$store.state.current.ecdis.permits_backup.at(1);
      this.form.email = this.$store.state.current.ecdis.email;
      this.form.ship_name = this.$store.state.current.ecdis.ship_name;
      this.form.ship_imo = this.$store.state.current.ecdis.ship_imo;
      this.form.ship_mmsi = this.$store.state.current.ecdis.ship_mmsi;
      this.form.ship_callsign = this.$store.state.current.ecdis.ship_callsign;
      this.form.country = this.$store.state.current.ecdis.country;

      this.form.ship_category = this.$store.state.current.ecdis.ship_category;
      this.form.ecdis_brand = this.$store.state.current.ecdis.ecdis_brand;
      this.form.ecdis_os = this.$store.state.current.ecdis.ecdis_os;
    }

    // eventBus.$on("edit-ecdis", () => {
    //   this.form.permit_main = "ssss"
    //   console.log("edit ecdis")
    // })
  },
  computed: {
    emailsState() {
      return !this.form.email.includes(" ") && this.form.email.includes("@");
    },
    imoState() {
      return this.form.ship_imo.length === 10 && this.form.ship_imo.startsWith("IMO");
    },
    callsignState() {
      return this.form.ship_callsign.length <= 10;
    },
    mmsiState() {
      return this.form.ship_mmsi.length < 20;
    }
  },
  methods: {
    gotoMyDevices() {
      // window.location.reload();
      router.push({name: 'devices'});
      // let y = setTimeout(() => {
      //   window.location.reload();
      // }, 1000)
    },
    registerEcdis() {
      alert(1)

      let userID = this.$store.state.current.profile.user_id;
      let deviceID = this.$store.state.current.profile.device_id;
      let URL = "/api/v1/ecdis/register";

      let data = {
        userPermit: this.form.permit_main,
        notificationEmails: this.form.email,
        deliveryEmail: this.form.deliveryEmail,
        backupUserPermits: this.form.permits_backup,
        shipInfo: {name: this.form.ship_name,
          imo: this.form.ship_imo,
          mmsi: this.form.ship_mmsi,
          callSign: this.form.ship_callsign,
          category: this.form.ship_category,
          ecdisBrand: this.form.ecdis_brand,
          ecdisOs: this.form.ecdis_os,
          flag: this.form.country
        },
      }

      if (this.$store.state.current.ecdis.edit) {
        URL = "/api/v1/ecdis/update"
        data["deviceId"] = deviceID;
      }

      console.log(data)

      myAxios.post(URL, data).then(resp => {
        console.log(resp.status)
        if (resp.status === 200) {
          this.modalShow_ok = true;
        } else {
          this.modalShow_nok = true;
          console.log("TODO: error")
        }
      })
      return false
    },
  }
}
</script>

