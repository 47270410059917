import { render, staticRenderFns } from "./PassagePlan.vue?vue&type=template&id=56b7f721&scoped=true&"
import script from "./PassagePlan.vue?vue&type=script&setup=true&lang=js&"
export * from "./PassagePlan.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b7f721",
  null
  
)

export default component.exports