import {myAxios} from "@/services/myAxios";
import {myAxiosRetry} from "@/services/myAxios";
import {file} from "paths.macro";
// import {commit} from "lodash/seq";
import axios from "axios";
import {CURRENT_PRODUCT} from "@/utils/build-config";

import {getAppType} from "@/utils/utils";
import store from "@/store";
import Vue from "vue";
import {eventBus} from "@/main";
import {useDeviceStore} from "@/stores/device";

const hashPeriodBands = {12: 0, 6: 1, 3: 4};
const BandPeriods = {0: '12 months', 1: `6 months`, 4: '3 months'};

const initialCart = {
    device_id: "",
    user_id: "",
    email: "",
    device_os: "",
    app_name: "",
    app_version: "",
    pos_lat: "",
    pos_lon: "",
}

const initialState = {
    webuser: {
        ip: "",
        location: "",
    },
    user_type: {
        is_mconnect: false,
        device_id: "",
    },
    profile: initialCart,
        // localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) :
        //     initialCart,
    ship: {
        draught: 10,
    },
    cart:
        // localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) :

            {
        s63cells: [],
        totalPriceByPrimar: '',
        totalPriceByPrimarNet: '',
        totalPriceByPrimarAdd: '',
        totalPriceByPrimarRUB: '',
        // totalPriceByPrimarEURO_RU: '',
        totalPriceByPrimarAgreementRUB: '',
        totalPriceByPrimarAgreementUSD: '',
        totalPriceByPrimarAgreementEURO_RU: '',
        totalPriceByPrimarDisplay: '',
        totalPriceByGP: 0,
        currentBandPeriods: BandPeriods,
        cellPrices: []
    },
    loading: {
        loadingMarkers: [],
        geocontent: false,
        orders: false,
        primarInfo: false,
        drawing: false
    },
    s63_payment: {
        back_from_store: false,
        payment_success: false,
    },
    s63_primar: {
        info_loaded: false,
    },
    geocontent: {
        s63_id: 0,
    },
    product: {
        current: "",
    },
    hints: {
        showDevicesHint: true,
        showChartsHint: true,
    },
    ecdis: {
        edit: false,

        email: '',
        ship_name: '',
        ship_imo: '',
        ship_mmsi: '',
        ship_callsign: '',
        ship_category: '',
        country: '',

        ecdis_brand: '',
        ecdis_os: '',
        ecdis_type: '',

        permit_main: '',
        permits_backup: [],

    },
    agreement: {
        id: -1,
        currency: '',
        validdate: ''
    }
}

export const current = {
    namespaced: true,
    state: initialState,
    actions: {
        getPeriodList({dispatch, commit}, products) {
            console.log(file, "getPeriodList : start")
            const cartItems = products;
            console.log(cartItems)
            let result = {}
            if (cartItems[0]) {
                let intersection = cartItems[0].periods
                cartItems.forEach(item => {
                    const set = item.periods;
                    console.log(item, file)
                    intersection = intersection.filter(x => set.includes(x));
                })
                intersection.forEach(period => {

                    if (period === 12 || period === 6 || period === 3) {
                        console.log(hashPeriodBands[period], period)
                        result[hashPeriodBands[period]] = BandPeriods[hashPeriodBands[period]];
                    }
                })
            }

            // change period for S102 maps
            cartItems.forEach(item => {
                if (item.id.startsWith("102"))
                    result = {0: "12 months", 1: "6 months"};
            })

            commit("setCurrentPeriodList", result);
            console.log(file, "getPeriodList : end")
        },
        getWebUserLocation({commit}) {
            console.log(file, "getWebUserLocation");
            // axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=6bdbc2eb8ac6474489f7b5410edef0ec').then( resp => {
            //     commit("setWebUserLocation", {ip: resp.data.ip_address, location: resp.data.country_code});
            // })
            axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=c4ef09fdb3104249a0e720e8cd1a92a0').then(resp => {
                commit("setWebUserLocation", {ip: resp.data.ip_address, location: resp.data.country_code});
            })
        },
        getCellPrice({commit}, {cell, periodBand, agreementID, deviceID, appType}) {

            let cellArray = store.state.current.cart.cellPrices
            let objIndex = cellArray.findIndex((obj => obj.id === cell.id));

            console.log("aaaaaaaaaaa")
            console.log("aaaaaaaaaaa")
            console.log("aaaaaaaaaaa")
            console.log("aaaaaaaaaaa")
            console.log(cell.id, objIndex)

            if (objIndex !== -1)
                return

            let items = [];
            items.push(cell.id)

            // alert(1)
            let quotate = {
                items: items,
                subscriptionType: periodBand,
                agreementId: agreementID,
                deviceId: deviceID,
                applicationTypeId: appType,
                quotateManually: true
            };

            // alert("a6")
            return new Promise(resolve => {
                // alert("a1")
                myAxiosRetry.post('/api/v1/orders/primar/quotate/v3', quotate).then(resp => {
                    // myAxiosRetry.post('/api/v1/orders/primar/quotate/v3', quotate).then(resp => {
                    // myAxios.post('/api/v1/orders/primar/quotate/v31', quotate, {retry: 3}).then(resp => {
                    let cellPrice = Number(resp.data.euroPrice).toFixed(2)
                    commit("setCellPrice", {id: cell.id, price: cellPrice})
                    resolve(cellPrice)
                })
            })

            //     .catch(reason => {
            //     console.log("net error 1 ", reason.response.status)
            //     if (reason.response.status > 204) {
            //         console.log("net error 1: ", reason.response.status)
            //         myAxios.post('/api/v1/orders/primar/quotate/v3', quotate, {timeout: 5000}).then(resp => {
            //             let cellPrice = Number(resp.data.euroPrice).toFixed(2)
            //             commit("setCellPrice", {id: cell.id, price: cellPrice})
            //         }).catch(reason => {
            //             console.log("net error 2: ", reason.response.status)
            //         })
            //     }
            // })
        },
        getTotalPrice({commit}, {products, periodBand, agreementID, deviceID, appType, isManualCalculating = true}) {
            alert(1)
            let deviceStore = useDeviceStore()
            let provider = deviceStore.getAgreementProvider

            return new Promise(resolve => {
                console.log(file, "getTotalPrice : start");
                console.log('cells', products);
                console.log('period', periodBand);
                console.log('device', deviceID);



                if (products.length > 0 && periodBand) {
                    let items = [];
                    products.forEach(x => {
                        if (!x.disabled)
                            items.push(x.id)
                    })

                    let quotate = {}

                    if (provider === 'UKHO') {
                        let months = 3
                        if (periodBand === '1')
                            months = 6
                        if (periodBand === '0')
                            months = 12

                        quotate = {
                            "cells": items,
                            "monthCount": months
                        }
                    }

                    if (provider === 'Primar') {
                        quotate = {
                            items: items,
                            subscriptionType: periodBand,
                            agreementId: agreementID,
                            deviceId: deviceID,
                            applicationTypeId: appType,
                            quotateManually: isManualCalculating
                        };
                    }

                    // TODO NAVICOM
                    if (CURRENT_PRODUCT.is_navicom) {
                        commit("setTotalPriceByPrimar",
                            {
                                euro: 4 * products.length,
                                rub: 350 * products.length,
                                agreementUsd: 0,
                                agreementRub: 0,
                                displayPrice: 350 * products.length,
                            }
                        )
                        console.log('1')
                    } else {
                        // myAxiosRetry.post('/api/v1/orders/primar/quotate/v3', quotate).then(resp => {
                        // alert("a2")
                        let URL = "/api/v1/orders/primar/quotate/v3"
                        if (provider === 'UKHO') {
                            URL = "api/v1/mss/avcquotate"
                        }

                        myAxiosRetry.post(URL, quotate).then(resp => {
                            // alert(2)
                            // cell.price = price;

                            let usdPrice = 0
                            if (provider === 'UKHO') {
                                resp.data.uoses.forEach(item => {
                                    usdPrice += item.price
                                })

                                commit("setTotalPriceByPrimar",
                                    {
                                        euro: 0,
                                        rub: 0,
                                        euroadd: 0,
                                        euronet: 0,
                                        agreementUsd: String(usdPrice),
                                        agreementRub: 0,
                                        displayPrice: usdPrice,
                                    })

                                commit("clearCellPrices")
                                resp.data.uoses.forEach(item => {
                                    let cellPriceEuro = 0
                                    let cellPriceRub = 0
                                    let cellPriceAgreementUsd = String(item.price)
                                    let cellPriceAgreementRub = 0
                                    commit("setCellPrice", {id: item.name, priceEuro: cellPriceEuro, priceRub: cellPriceRub, priceAgreementUsd : cellPriceAgreementUsd, priceAgreementRub : cellPriceAgreementRub})

                                })
                            }

                            if (provider === 'Primar') {
                                commit("setTotalPriceByPrimar",
                                    {
                                        euro: Number(resp.data.userEuro).toFixed(2),
                                        rub: Number(resp.data.userRub).toFixed(2),
                                        euroadd: Number(resp.data.userEuroAdd).toFixed(2),
                                        euronet: Number(resp.data.euroPrice).toFixed(2),
                                        agreementUsd: Number(resp.data.agreementUsd).toFixed(2),
                                        agreementRub: Number(resp.data.agreementRub).toFixed(2),
                                        displayPrice: Number(resp.data.displayPrice).toFixed(2),
                                    })

                                commit("clearCellPrices")
                                resp.data.itemsPrice.forEach(item => {
                                    let cellPriceEuro = Number(item.userEuro).toFixed(2)
                                    let cellPriceRub = Number(item.userRub).toFixed(2)
                                    let cellPriceAgreementUsd = Number(item.agreementUsd).toFixed(2)
                                    let cellPriceAgreementRub = Number(item.agreementRub).toFixed(2)
                                    commit("setCellPrice", {id: item.id, priceEuro: cellPriceEuro, priceRub: cellPriceRub, priceAgreementUsd : cellPriceAgreementUsd, priceAgreementRub : cellPriceAgreementRub})

                                })
                            }


                            console.log('2')
                            resolve(this.state.totalPriceByPrimar);
                        })
                            // .catch(reason => {
                            // console.log("net error 1 ", reason.response.status)
                            // if (reason.response.status > 204) {
                            //     console.log("net error 1: ", reason.response.status)
                            //     myAxios.post('/api/v1/orders/primar/quotate/v3', quotate, {timeout: 5000}).then(resp => {
                            //         commit("setTotalPriceByPrimar",
                            //             {
                            //                 euro: Number(resp.data.userEuro).toFixed(2),
                            //                 rub: Number(resp.data.userRub).toFixed(2),
                            //                 euroadd: Number(resp.data.userEuroAdd).toFixed(2),
                            //                 euronet: Number(resp.data.euroPrice).toFixed(2),
                            //                 agreementUsd: Number(resp.data.agreementUsd).toFixed(2),
                            //                 agreementRub: Number(resp.data.agreementRub).toFixed(2),
                            //                 displayPrice: Number(resp.data.displayPrice).toFixed(2),
                            //             }
                            //         )
                            //     }).catch(reason => {
                            //         console.log("net error 2: ", reason.response.status)
                            //     })
                            // }
                        // })

                    }

                } else {
                    commit("setTotalPriceByPrimar", '');
                    console.log('3');
                }


                console.log(file, "getTotalPrice : end")

            })

        },
        addTotalPriceByGP({commit}, fixedPrice) {
            commit("setTotalPriceByGP", fixedPrice.toFixed(2));
        },
        addProfile({commit}, payload) {
            commit("setProfile", payload);
        },
        addGeoContent({commit}, payload) {
            commit("setGeocontent", payload);
        },
        addPrimarMapInfosState({commit}, payload) {
            commit("setPrimarMapInfosState", payload);
        },
        async addS63Cart({commit, dispatch}, payload) {
            console.log(file, "addS63Cart : start")
            console.log(payload)

            dispatch("getPeriodList", payload);

            commit("setS63Cart", payload);
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));
            console.log(file, "addS63Cart : end")
        },
        addLoadingMarker({commit}, marker) {
            commit("pushLoadingMarker", marker);
        },
        removeLoadingMarker({commit}, marker) {
            commit("popLoadingMarker", marker);
        },
        loadingGeoContent({commit}, is_loading) {
            commit("setGeoContentStatus", is_loading);
        },
        loadingOrders({commit}, is_loading) {
            commit("setOrdersStatus", is_loading);
        },
        loadingSummary({commit}, is_loading) {
            commit("setOrdersStatus", is_loading);
        },
        drawing({commit}, is_loading) {
            commit("setDrawStatus", is_loading);
        },
        updatePaymentStatus({commit}, payload) {
            commit("setPaymentStatus", payload);
        },
        updateCellStaus({commit}, payload) {
            return new Promise(resolve => {
                console.log(payload.id)
                commit("setCellStaus", payload)
                let objIndex = current.state.cart.s63cells.findIndex((obj => obj.id === payload.id));
                resolve(current.state.cart.s63cells[objIndex].disabled)
            })

            //Find index of specific object using findIndex method.


            // //Update object's name property.
            // myArray[objIndex].name = "Laila"
            //
            // //Log object to console again.
            // console.log("After update: ", myArray[objIndex])

        },
        clearCart({commit}) {
            return new Promise(resolve => {
                commit("setCartClear")
                resolve()
            })
        }
    },
    mutations: {
        setCartClear1(state) {
            state.cart.totalPriceByPrimar = '';
            // localStorage.setItem('cart', JSON.stringify({
            //     totalPriceByPrimar: ''
            // }));
        },
        setCartClear(state) {
            // state.cart = initialCart;
            // localStorage.setItem('cart', JSON.stringify({
                state.cart.s63cells = [],
                    state.cart.totalPriceByPrimar = '',
                    state.cart.totalPriceByPrimarNet = '',
                    state.cart.totalPriceByPrimarAdd = '',
                    state.cart.totalPriceByPrimarRUB = '',
                // totalPriceByPrimarEURO_RU: '',
                    state.cart.totalPriceByPrimarAgreementRUB = '',
                    state.cart.totalPriceByPrimarAgreementUSD = '',
                state.cart.totalPriceByPrimarAgreementEURO_RU = '',
                state.cart.totalPriceByPrimarDisplay = '',
                state.cart.totalPriceByGP = 0,
                state.cart.currentBandPeriods = BandPeriods,
                state.cart.cellPrices = []
            // }));
        },
        setCellStaus(state, payload) {
            let objIndex = state.cart.s63cells.findIndex((obj => obj.id === payload.id));
            if (payload.type === 'switch')
                state.cart.s63cells[objIndex].disabled = !state.cart.s63cells[objIndex].disabled

            if (payload.type === 'on')
                state.cart.s63cells[objIndex].disabled = false

            if (payload.type === 'off')
                state.cart.s63cells[objIndex].disabled = true


            //
            // console.log("aaaaaaa")
            // console.log(objIndex)
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setWebUserLocation(state, payload) {
            state.webuser.location = payload.location;
            state.webuser.ip = payload.ip;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        // setCellPrice(state, payload) {
        //     console.log(payload.id, payload.price)
        //     // state.cart.cellPrices[payload.id] = payload.price
        //     let x = {}
        //     // x[payload.id] = payload.price
        //     state.cart.cellPrices.push({id: payload.id, price: payload.price})
        //     // Vue.set(state.cart.cellPrices, payload.id, payload.price)
        //     localStorage.setItem('cart', JSON.stringify(current.state.cart));
        //
        // },
        clearCart(state) {
            state.cart.cellPrices = []
            state.cart.s63cells = []
            state.cart.totalPriceByPrimar = ''
            state.cart.totalPriceByPrimarNet = ''
            state.cart.totalPriceByPrimarAdd = ''
            state.cart.totalPriceByPrimarRUB = ''
            state.cart.totalPriceByPrimarAgreementRUB = ''
            state.cart.totalPriceByPrimarAgreementUSD = ''
            state.cart.totalPriceByPrimarAgreementEURO_RU = ''
            state.cart.totalPriceByPrimarDisplay = ''
            state.cart.totalPriceByGP = 0
            state.cart.currentBandPeriods = BandPeriods
            state.cart.cellPrices = []
        },

        clearCellPrices(state) {
            state.cart.cellPrices = []
        },
        setCellPrice(state, payload) {
            state.cart.cellPrices.push({id: payload.id, priceEuro: payload.priceEuro, priceRub: payload.priceRub, priceAgreementUsd: payload.priceAgreementUsd, priceAgreementRub: payload.priceAgreementRub})
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setTotalPriceByPrimar(state, payload) {
            state.cart.totalPriceByPrimar = payload.euro ? payload.euro : ""
            state.cart.totalPriceByPrimarNet = payload.euronet? payload.euronet : ""
            state.cart.totalPriceByPrimarAdd = payload.euroadd? payload.euroadd : ""
            state.cart.totalPriceByPrimarRUB = payload.rub? payload.rub : ""
            // state.cart.totalPriceByPrimarEURO_RU = payload.rubineuro;
            state.cart.totalPriceByPrimarDisplay = payload.displayPrice? payload.displayPrice : ""
            // state.cart.totalPriceByPrimarAgreementEURO_RU = payload.agreementUsd;
            state.cart.totalPriceByPrimarAgreementRUB = payload.agreementRub? payload.agreementRub : ""
            state.cart.totalPriceByPrimarAgreementUSD = payload.agreementUsd? payload.agreementUsd : ""
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));


        },
        setTotalPriceByGP(state, payload) {
            state.cart.totalPriceByGP = payload;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setGeocontent(state, payload) {
            state.geocontent.s63_id = payload.s63_id;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setProfile(state, payload) {
            Object.assign(state.profile, initialState.profile);

            state.profile.device_id = payload.device_id;
            state.profile.user_id = payload.user_id;
            state.profile.email = payload.email;
            state.profile.device_os = payload.device_os;
            state.profile.app_name = payload.app_name;
            state.profile.app_version = payload.app_version;
            state.profile.pos_lat = payload.pos_lat;
            state.profile.pos_lon = payload.pos_lon;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setPrimarMapInfosState(state, payload) {
            state.s63_primar.info_loaded = payload;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setS63Cart(state, payload) {
            console.log("------------")
            console.log(state.cart.s63cells)
            console.log(payload)

            let oldCart = state.cart.s63cells
            console.log(payload, oldCart, 'po')
            // state.cart.s63cells = [];
            state.cart.s63cells = payload;

            oldCart.forEach((item) => {
                if (item.disabled) {
                    state.cart.s63cells.push(item)
                    // console.log(item.id)
                    // let id = item.id;
                    // let index = state.cart.s63cells.findIndex((obj => obj.id === id));
                    // console.log(index)
                    // if (index !== -1)
                    //     state.cart.s63cells[index].disabled = true
                }
            })
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));


        },
        setAgreement(state, payload) {
            state.agreement.id = payload.id;
            state.agreement.currency = payload.currency;
            state.agreement.validdate = payload.validtill;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        pushLoadingMarker(state, marker) {
            state.loading.loadingMarkers.push(marker)
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        popLoadingMarker(state, marker) {
            const index = state.loading.loadingMarkers.indexOf(marker);
            if (index > -1) { // only splice array when item is found
                state.loading.loadingMarkers.splice(index, 1); // 2nd parameter means remove one item only
            }
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setGeoContentStatus(state, is_loading) {
            state.loading.geocontent = is_loading;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setPrimarInfoStatus(state, is_loading) {
            if (is_loading === false) {
                setTimeout(function () {
                }, 3000);
            }
            state.loading.primarInfo = is_loading;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setOrdersStatus(state, is_loading) {
            state.loading.orders = is_loading;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setDrawStatus(state, is_loading) {
            state.loading.drawing = is_loading;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setPaymentStatus(state, payload) {
            state.s63_payment.back_from_store = payload.back_from_store;
            state.s63_payment.payment_success = payload.is_success;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setCurrentPeriodList(state, paylolad) {
            state.cart.currentBandPeriods = paylolad;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setCurrentProduct(state, product) {
            state.product.current = product;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setCurrentDraught(state, draught) {
            state.ship.draught = draught;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setMConnectUser(state, payload) {
            state.user_type.is_mconnect = payload.is_mconnect;
            state.user_type.device_id = payload.device_id;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setHintsCharts(state, flag) {
            state.hints.showChartsHint = flag;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setHintsDevices(state, flag) {
            state.hints.showDevicesHint = flag;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setEditECIDSFlag(state, flag) {
            state.ecdis.edit = flag;
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        },
        setECDIS(state, payload) {
            state.ecdis.email = payload.ecids_emails
            state.ecdis.delivery_email = payload.ecdis.delivery_email
            state.ecdis.ship_name = payload.ecids_ship_name
            state.ecdis.ship_imo = payload.ecids_ship_imo
            state.ecdis.ship_mmsi = payload.ecids_ship_mmsi
            state.ecdis.ship_callsign = payload.ecids_ship_callsign
            state.ecdis.ship_pid = payload.ecids_ship_pid
            state.ecdis.ship_category = payload.ecids_ship_category
            state.ecdis.ecdis_brand = payload.ecids_brand
            state.ecdis.ecdis_os = payload.ecids_os
            state.ecdis.country = payload.country
            state.ecdis.permit_main = payload.ecids_permit
            state.ecdis.permits_backup = payload.ecids_backup_permit
            // localStorage.setItem('cart', JSON.stringify(current.state.cart));

        }
    }
}