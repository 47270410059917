import {defineStore} from 'pinia'
import {useDeviceStore} from "@/stores/device";

export const useUserStore = defineStore('user', {
    state: () => ({
        isShipsReady: false,
        isInstallationsReady: false,
        isAgreementInstallationsReady: false,

        ECDISList: [],
        installationsECDIS: [],
        installationsDevices: [],
        ordersByDevices: [{id: 1, orders: 2}]
    }),
    getters: {
        isShipsLoaded: (state) => state.isShipsReady,
        isInstallationsLoaded: (state) => state.isInstallationsReady,
        // getShipName: (state) => {
        //     let deviceStore = useDeviceStore()
        //     let deviceId = deviceStore.getDeviceID
        //
        //     let installation = state.installationsECDIS.find((a) => a.deviceId == deviceId)
        //     return installation?.primarInfo?.shipInfo?.name ?? ''
        // },

        getShipName: (state) => {
            let deviceStore = useDeviceStore()
            let deviceId = deviceStore.getDeviceID

            let installation = state.ECDISList.find((a) => a.deviceId == deviceId)
            return installation?.shipName ?? ''
        },

        getECDISShips: (state) => {
            let installations = []
            state.ECDISList.forEach(item => {
                installations.push({value: item.deviceId, text: item.shipName, email: item.email})
            })

            // TODO warning and restrict access to other menu
            // if (installations.length === 0)
            //     router.push({name: 'ships'})

            return installations
        },

        // getShips: (state) => {
        //     let installations = []
        //     state.installationsECDIS.forEach(item => {
        //         installations.push({value: item.deviceId, text: item.shipName})
        //     })
        //     return installations
        // },
        // getAppTypeByDeviceID: (state) => {
        //     return (val) => state.installationsECDIS.find((a) => a.deviceId === val)?.applicationId
        // },
        // getInstallationByDeviceID: (state) => {
        //     return (val) => state.installationsECDIS.find((a) => a.deviceId === val)
        // },
    },
    actions: {
        addECDISShips(payload) {
            let sortedList = payload.ecdisList.sort((a, b) => (a.shipName > b.shipName) ? 1 : ((b.shipName > a.shipName) ? -1 : 0))
            this.ECDISList = sortedList
            this.isShipsReady = true
        },
        addECDISAgreementShips(payload) {
            payload.ecdisList.forEach(item1 => {
                let index = this.ECDISList.findIndex(item2 => item1.deviceId == item2.deviceId)
                if (index !== -1)
                    this.ECDISList.splice(index, 1);
            })

            if (!this.isAgreementInstallationsReady) {
                let sortedList = payload.ecdisList.sort((a, b) => (a.shipName > b.shipName) ? 1 : ((b.shipName > a.shipName) ? -1 : 0))
                this.ECDISList.push(...sortedList)
                this.isAgreementInstallationsReady = true
            }
            this.isShipsReady = true
        },
        addInstallations(payload) {
            this.installationsECDIS = payload.ecdisList
            this.installationsDevices = payload.devicesList
            this.isInstallationsReady = true
        },
        addOrders(payload) {
            this.ordersByDevices.push({id: payload.id, orders: payload.orders})
        },
        reset() {
        }
    },
    persist: false
})
