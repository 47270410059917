<template>
  <div>
    <b-overlay
        :show="$store.state.fickle.offline.userDataLoading || $store.state.fickle.offline.userInstallationsLoading"
        opacity=0.9 bg-color="white" class="mt-4">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p> Loading installations...</p>
        </div>
      </template>

      <b-container fluid>
<!--        <b-overlay :show="spinner" rounded="sm" spinner-variant="danger">-->
<!--          <template #overlay>-->
<!--            <div class="text-center text-danger my-2">-->
<!--              <b-spinner class="align-middle"></b-spinner>-->
<!--              <strong>&nbsp;{{ $store.state.fickle.requests.current_request }}</strong>-->
<!--            </div>-->
<!--          </template>-->
          <b-row>
            <b-col cols="12">
              <b-card>
<!--                <b-row>-->
<!--                  <b-col class="text-right">-->
<!--                    <b-dropdown size="sm" variant="info" text="Add ship">-->
<!--                      <b-dropdown-item @click="scanQR()">By QR code</b-dropdown-item>-->
<!--                      <b-dropdown-item @click="addEcdis()">Manually</b-dropdown-item>-->
<!--                    </b-dropdown>-->
<!--                  </b-col>-->
<!--                </b-row>-->
                <ships-lists ref="ships-list"></ships-lists>
              </b-card>
            </b-col>
          </b-row>
<!--        </b-overlay>-->
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import {file} from 'paths.macro';
import {eventBus} from "@/main";
import {NavLeaf} from "@/my-leaf/base/nav-leaf";
import {IS_GETENC, IS_NP} from "@/utils/build-config";
import ShipsLists from "@/views/ships/ShipsLists.vue";
import router from "@/router";
import store from "@/store";
import {checkInternetLoop, loadENCData, loadUserData, loadUserInstallations} from "@/helpers/offline";
import {myAxios} from "@/services/myAxios";
import {loadInstallations} from "@/helpers/api";

export default {
  components: {ShipsLists},
  data() {
    return {
      interval: null,
      spinner: false,
      show_add_ship_tab: "d-none",
      tab_name: "Add new ship",
      selected_tab: 1,
      // add_ecdis_by_qr: false,
      edit_ecdis: false,
      selected_ecdis: null,
      map: new NavLeaf(),

      ship: {
        order_number: null,
        order_date: null,
        ecdis_name: null,
        ecdis_imo: null,
        device_id: null,
        application_id: null
      }
    }
  },
  created() {
    // eventBus.$on('show-ship-appstate', data => {
    //   this.map.showAppState(data.lat, data.lon, data.cog);
    // });
  },
  computed: {
    // spinner(){
    //   return this.$store.state.proto.agreement.buzy;
    // }
  },
  mounted() {
    myAxios.get('/api/v1/manage/test')

    loadInstallations()

    if (!store.state.fickle.offline.userInstallationsReady) {
      loadUserInstallations().then(resp => {
        console.log("off, INSTALLATIONS DONE")
      })
    }
    if (!store.state.fickle.offline.userDataReady) {
      loadUserData().then(resp => {
        console.log("off, USER DONE")
      })
    }
    // if (!store.state.fickle.offline.encDataReady) {
    //   loadENCData().then( resp => {
    //     console.log("off, ENC DONE")})
    // }

    // this.interval = setInterval(checkInternetLoop, 5000)

    if (this.$route.params) {
      if (this.$route.params.qrevent === 'add-ecdis-by-qr') {
        this.edit_ecdis = false
        this.$root.$emit('bv::toggle::collapse', 'ship-edit');
        eventBus.$emit('add-ecdis-by-qr-1')
      }

      if (this.$route.params.qrevent === 'update-ecdis-by-qr') {
        this.edit_ecdis = true
        this.$root.$emit('bv::toggle::collapse', 'ship-edit');
        eventBus.$emit('update-ecdis-by-qr-1')
      }

    }

    // eventBus.$on('add-ecdis-by-qr', () => {
    //   // this.add_ecdis_by_qr = true
    //   this.showNewShipTab()
    // })

    // eventBus.$on('edit-ecdis', () => {
    //   // this.add_ecdis_by_qr = true
    //   this.editECDIS()
    // })

    eventBus.$off('reload-installations');
    eventBus.$off('add-ecdis-by-qr-a')
    eventBus.$off('update-ecdis-by-qr')
    eventBus.$off('ecdis-selected')

    eventBus.$on('add-ecdis-by-qr-a', () => {
      // this.add_ecdis_by_qr = true
      this.edit_ecdis = false
      // this.tab_name = 'Edit ship'
      // this.$root.$emit('bv::toggle::collapse', 'qr-details')

      setTimeout(() => {
        this.$root.$emit('bv::toggle::collapse', 'ship-edit');
        eventBus.$emit('add-ecdis-by-qr-1')
      }, 1000);


    })

    eventBus.$on('update-ecdis-by-qr', () => {
      // this.add_ecdis_by_qr = true
      this.edit_ecdis = true
      // this.tab_name = 'Edit ship'
      this.$root.$emit('bv::toggle::collapse', 'ship-edit');
      eventBus.$emit('update-ecdis-by-qr-1')
    })

    eventBus.$on('ecdis-selected', (item) => {
      this.selected_ecdis = item
    })

    eventBus.$on('reload-installations', () => {
      window.location.reload()
    })

    // this.$store.commit("routes/resetStorage")
    // this.$store.dispatch('proto/updateOrder', {step: "creating"})
    // this.$store.dispatch('addS63Items', [])

    // localStorage.removeItem('route')
    // localStorage.removeItem('proto')
    // localStorage.removeItem('cart')
    //
    //AAA
    // this.$store.dispatch("proto/getInstallations").then(() => {
    //   localStorage.setItem('proto', JSON.stringify(store.state.proto));
    //
    // })
    this.$store.commit('proto/setRestoredOrder', null)
    this.$store.dispatch('proto/updateOrder', {
      update: false,
    })
  },
  methods: {
    isECDISSelected() {
      return this.ship.device_id
      // return (this.$refs['ships-list'] && this.$refs['ships-list'].ship && this.$refs['ships-list'].ship.device_id)
    },
    isDeviceSelected() {
      return (this.$refs['ships-devices-list'] && this.$refs['ships-devices-list'].ship && this.$refs['ships-devices-list'].ship.device_id)
    },
    IS_GETENC() {
      return IS_GETENC
    },

    async addBook() {

      // await this.$store.dispatch('dbase/deleteBookFromDb')

      const book = {
        name: 'Book_' + Math.floor(Math.random() * 100),
        price: Math.floor(Math.random() * 10) + 1
      }
      await this.$store.dispatch('dbase/addBookToDb', book)
      await this.$store.dispatch('dbase/getBooks')

    },

    async deleteBook(book) {
      await this.$store.dispatch('deleteBookFromDb', {book})
      await this.getBooks()
    },

    // addEcdis() {
    //
    //   // this.addBook()
    //   this.$root.$emit('bv::toggle::collapse', 'ship-edit');
    //   eventBus.$emit('clean-ecdis')
    // },
    showNewShipTab() {
      this.$root.$emit('bv::toggle::collapse', 'ship-edit');
    },
    // scanQR() {
    //   // router.push({name:'transactions'})
    //   // this.$refs["report-modal"].hide()
    //   setTimeout(() => {
    //     eventBus.$emit('start-scan-qr-ecdis')
    //   }, 500)
    // },
    editECDIS() {
      this.edit_ecdis = true
      this.showNewShipTab()
      this.tab_name = 'Edit ship'

      console.log(this.selected_ecdis)

      eventBus.$emit('edit-ecdis', this.selected_ecdis)
    },
    shipSelected(type) {
      this.spinner = true
      this.$refs["ships-list"].onDone("ships")
      this.$store.commit("routes/useRouteForOrder", false)
      this.$store.commit("routes/resetStorage")

      this.$store.dispatch('proto/selectShip', true).then(() => {

        // this.spinner = false
        // router.push({name: 'charts'}).catch(() => {});
        //AAA
        this.$store.dispatch('proto/getAgreementsV2').then(() => {
          // localStorage.setItem('proto', JSON.stringify(store.state.proto));
          this.spinner = false
          if (type === 'ships-ecdis-list-primar')
            router.push({name: 'order-primar'}).catch(() => {
            });
          else
            router.push({name: 'order-ukho'}).catch(() => {
            });
        })
      })

      setTimeout(() => {
        eventBus.$emit('select-ship-from-ships', "1")
      }, 1000);


      // eventBus.$emit('set-new-ship-text-on-chart', this.$store.state.proto.order.ecdis_name)
      // eventBus.$emit('set-new-order-text-on-chart', {order: this.$store.state.proto.order.order_number, order_date: this.$store.state.proto.order.order_date})
      // eventBus.$emit('ship-selected-from-chart')
      // this.$store.dispatch('proto/updateOrder', {step: "creating"})


    },
    shipSelected1(type) {
      this.spinner = true
      this.$refs["ships-list"].onDone("ships")
      this.$store.commit("routes/useRouteForOrder", false)
      this.$store.commit("routes/resetStorage")

      this.$store.dispatch('proto/selectShip', true).then(() => {

        // this.spinner = false
        // router.push({name: 'charts'}).catch(() => {});
        //AAA
        this.$store.dispatch('proto/getAgreementsV2').then(() => {
          // localStorage.setItem('proto', JSON.stringify(store.state.proto));
          this.spinner = false
          router.push({name: 'routes'}).catch(() => {
          });
        })
      })

      setTimeout(() => {
        eventBus.$emit('select-ship-from-ships', "1")
      }, 1000);


      // eventBus.$emit('set-new-ship-text-on-chart', this.$store.state.proto.order.ecdis_name)
      // eventBus.$emit('set-new-order-text-on-chart', {order: this.$store.state.proto.order.order_number, order_date: this.$store.state.proto.order.order_date})
      // eventBus.$emit('ship-selected-from-chart')
      // this.$store.dispatch('proto/updateOrder', {step: "creating"})


    },
    IS_NP() {
      return IS_NP
    },
  },
}
</script>

<style>


/*.btn1[disabled] {*/
/*  background-color: #ababab;*/
/*  border-color: #aaaaaa;*/
/*}*/

</style>