import {myAxios, myAxiosRetry} from "@/services/myAxios";
import {file} from "paths.macro";

const initialState = {
    status: [
        {id:"13994", date:"2023-06-06 00:21", status:"completed", history: ["2022-12-12 14:12 order, aga@mkart.fi", "2022-12-12 15:12 paid, aga@mkart.fi", "2022-12-12 16:12 completed"]},
        {id:"14022", date:"2023-06-06 00:21", status:"request", history: ["2022-12-12 14:12 request, aga@mkart.fi"]},
        {id:"14234", date:"2022-06-06 00:21", status:"order", history: ["2022-12-12 14:12 order, aga@mkart.fi"]},
        {id:"18034", date:"2022-06-06 00:21", status:"completed", history: ["2022-12-12 14:12 order, aga@mkart.fi", "2022-12-12 15:12 paid, aga@mkart.fi", "2022-12-12 16:12 completed"]}
    ],
    orders: [
        {id:"13994", date:"2023-06-06 00:21", ship:"ANADYR", items:"Primar, Navicom", payment:"Ellcom Co.Ltd", price:"359.3 ₽", status:"completed"},
        {id:"14022", date:"2023-06-06 00:21", ship:"NAVIS 7", items:"Primar", payment:"Ellcom Co.Ltd", price:"", status:"request"},
        {id:"14234", date:"2022-06-06 00:21", ship:"SEF", items:"Primar", payment:"Ellcom Co.Ltd", price:"$12", status:"order"},
        {id:"18034", date:"2022-06-06 00:21", ship:"mKart", items:"Primar", payment:"Stripe", price:"$123", status:"completed"}
    ],
    selected_ship: {},
    imported_order: [],
    is_transactions_loading: false,
    transactions: []
}

export const transactions = {
    namespaced: true,
    state: localStorage.getItem('transactions') ? JSON.parse(localStorage.getItem('transactions')) :
        initialState,
    actions: {
        selectShip({dispatch, commit}, ship) {
            console.log(file, "selectShip : start")
            commit("setSelectedShip", ship)
            console.log(file, "selectShip : false")
        },
        importOrder({dispatch, commit}, order) {
            console.log(file, "importOrder : start")
            commit("setImportedOrder", order)
            console.log(file, "importOrder : false")
        },
        getTransactions({dispatch, commit}, userId='') {
            // alert("getTransactions")
            console.log(file, "getTransactions : start")
            commit("setTransactionsLoadingState", true)

            let URL = "api/v1/orders";
            // URL = 'http://localhost:8080/dev/_transactions.json'
            myAxiosRetry.get(URL).then(resp => {
                console.log(file, "getOrders: " + URL);
                console.log(resp.data)
                commit("setTransactions", resp.data)
                commit("setTransactionsLoadingState", false)
            });

        }
    },
    mutations: {
        setTransactionsLoadingState(state, flag) {
            state.is_orders_loading = flag;
        },
        setTransactions(state, orders) {
            state.transactions = orders;
        },
        setSelectedShip(state, ship) {
            state.selected_ship = ship;
        },
        setImportedOrder(state, order) {
            state.imported_order = order;
        },
    }
}