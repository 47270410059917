import Vue from 'vue'
import Vuex from 'vuex'
import {auth} from "@/store/modules/auth";
import {alert} from "@/store/modules/alert";
import {charts} from "@/store/modules/charts";
import {current} from "@/store/modules/current";
import {proto} from "@/store/modules/proto"
import {distributor} from "@/store/modules/distributor";
import {fickle} from "@/store/modules/fickle";
import {version} from "@/store/modules/version"
import {transactions} from "@/store/modules/transactions";
import {routes} from "@/store/modules/routes";
import {leaflet} from "@/store/modules/leaflet";
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {}, mutations: {}, actions: {}, modules: {
        auth, alert, version, charts, proto, current, routes, leaflet, distributor, fickle, transactions
    },

    plugins: [createPersistedState({
        storage: window.sessionStorage,
        paths: ['leaflet']
    })],
})
