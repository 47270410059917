<template>
  <div>
    <!--    <b-container v-show="momentaryStore.isBusy" fluid>-->
    <!--      <div align="center" class="text-centercenter text-danger my-2">-->
    <!--        <b-spinner class="align-middle"></b-spinner>-->
    <!--        <strong>&nbsp;Loading...</strong>-->
    <!--      </div>-->
    <!--    </b-container>-->

    <b-overlay :show="!userStore.isShipsLoaded || ($store.state.auth.user.role === 'distributor' && !deviceStore.isAgreementLoaded)" opacity=0.9 bg-color="white" class="mt-4">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p v-if="!userStore.isShipsLoaded">Loading ships...</p>
          <p v-if="$store.state.auth.user.role === 'distributor' && userStore.isShipsLoaded && !deviceStore.isAgreementLoaded">Loading agreements...</p>
        </div>
      </template>

      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-card header="TRANSACTIONS">
              <orders ref="orders"></orders>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

    </b-overlay>


    <!--  <b-container v-if="$store.state.fickle.offline.userDataLoading || $store.state.fickle.offline.encLoading" fluid>-->
    <!--    <div align="center" class="text-centercenter text-danger my-2">-->
    <!--      <b-spinner class="align-middle"></b-spinner>-->
    <!--      <strong>&nbsp;Loading...</strong>-->
    <!--    </div>-->
    <!--  </b-container>-->
    <!--  <b-container v-else fluid>-->
    <!--    <b-row>-->
    <!--      <b-col cols="12">-->
    <!--        <b-card header="ORDERS">-->
    <!--          <orders></orders>-->
    <!--        </b-card>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <!--  </b-container>-->
  </div>
</template>

<script>
import Orders from "@/views/transactions/Orders.vue";
import store from "@/store";
import {checkInternetLoop, loadUserData, loadUserInstallations} from "@/helpers/offline";
import {myAxios} from "@/services/myAxios";
import {mapStores} from "pinia";
import {useMomentaryStore} from "@/stores/momentary";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";
import {loadAgreements, loadInstallations, loadShips} from "@/helpers/api";

export default {
  components: {Orders},
  data() {
    return {
      interval: null,

      selected_ship: "",
      ships: []
    }
  },
  // created () {
  // },
  // beforeDestroy() {
  //   clearInterval(this.interval)
  // },
  // destroyed: function () {
  // },
  methods: {},
  watch: {
    'selected_ship'(newVal, oldVal) {
      this.deviceStore.selectDevice(newVal);
      this.$refs['orders'].checkUpdates()
    }
  },
  mounted() {

    myAxios.get('/api/v1/manage/test')

    loadShips().then((response) => {
      if (this.deviceStore.isDeviceIDExist)
        this.selected_ship = this.deviceStore.getDeviceID
      else
        this.selected_ship = this.userStore.getECDISShips[0]?.value ?? ''
      this.ships = this.userStore.getECDISShips

      if (this.$store.state.auth.user.role === 'distributor') {
        loadAgreements().then((response) => {
          if (this.deviceStore.isDeviceIDExist)
            this.selected_ship = this.deviceStore.getDeviceID
          else
            this.selected_ship = this.userStore.getECDISShips[0]?.value ?? ''
          this.ships = this.userStore.getECDISShips
        })
      }
    })


    if (this.$route.params.qrevent === 'reload-transactions') {
      loadUserData().then(resp => {
        console.log("off, INSTALLATIONS DONE")
      })
    }

    // myAxios.get('/api/v1/manage/test')

    // if (!store.state.fickle.offline.userInstallationsReady) {
    //   loadUserInstallations().then(resp => {
    //     console.log("off, INSTALLATIONS DONE")
    //   })
    // }
    if (!store.state.fickle.offline.userDataReady) {
      loadUserData().then(resp => {
        console.log("off, USER DONE")
      })
    }

  },
  computed: {
    ...mapStores(useMomentaryStore, useDeviceStore, useUserStore),
  }

}
</script>

<style scoped>
</style>