<template>
  <div>
    <div v-if="false" align="center" class="text-centercenter text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>&nbsp;Loading ...</strong>
    </div>
    <div v-else>
      <b-table v-if="routesStore.getShipWaypointsCount > 0" v-model="aaa" selectable sticky-header select-mode="single"
               ref="pptable"
               style="max-height: 400px;"
               @row-selected="onRowSelected" @row-clicked="onRowClicked" :items="items()" :fields="visible_fields"
               :tbody-tr-class="rowClass">

        <template #cell(name)="data">
          <span class='smt2s'>{{ data.index }}</span>
        </template>

        <template #cell(ukc.speed)="data">
          <span v-if="data.item?.ukc?.speed !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.speed"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.tide)="data">
          <span v-if="data.item?.ukc?.tide !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.tide"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.zoc)="data">
          <span v-if="data.item?.ukc?.zoc !== undefined">
            <b-form-input size="sm" type="text" min="0" max="5" step="0.5" v-model="data.item.ukc.zoc" lang="en"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.draft)="data">
          <span v-if="data.item?.ukc?.draft  !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.draft"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.ukcPolicy)="data">
          <span v-if="data.item?.ukc?.ukcPolicy  !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.ukcPolicy"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.isOpenWater)="data">
          <span v-if="data.item?.ukc?.isOpenWater !== undefined">
            <b-form-checkbox size="sm" v-model="data.item.ukc.isOpenWater"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-checkbox>
          </span>
        </template>

      </b-table>
    </div>

  </div>
</template>

<script>
import {eventBus, routes_map} from "@/main";
import store from "@/store";
import {myAxios} from "@/services/myAxios";
import {useRoutesStore} from "@/stores/routes";

import dayjs from "dayjs";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/user";
import {lineOffset} from "@turf/turf";
import {loadZOC} from "@/helpers/api";
import {midLatlon} from "@/utils/utils";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "PassagePlanTable",
  data() {
    return {
      prevRowIndex: -1,
      currentRowIndex: -1,
      centerRowIndex: -1,
      focusWPIndex: -1,
      aaa: [],
      weatherInWP: [],
      currentIndex: 0,
      isUKCReady: false,
    }
  },
  mounted() {
    eventBus.$off('select-pptable-row')
    eventBus.$on('select-pptable-row', (id) => {
      // this.currentRowIndex = id
      this.$refs.pptable.selectRow(id);
      const tbody = this.$refs.pptable.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[id]
      row.scrollIntoView({block: 'center'})
    })
  },
  watch: {},
  computed: {
    ...mapStores(useRoutesStore),
    visible_fields() {
      return [
        {key: 'name', label: '#', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'minDepth', label: 'Chart.D m', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.isSafe', label: 'Safe route', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.safeDepth', label: 'Safe.D m', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.ukc', label: 'UKC m', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.isOpenWater', label: 'Open waters', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.draft', label: 'Draft m', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        {key: 'ukc.speed', label: 'Speed kn', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        {key: 'ukc.ukcPolicy', label: 'Policy m', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        {key: 'ukc.squat', label: 'Squat m', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.tide', label: 'Tide m', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        {key: 'ukc.zoc', label: 'ZOC m', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        // {key: 'ukc.zoc1', label: 'ZOC (m)', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},

        // {key: 'ukc.displacement', label: '*Displacement', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'ukc.blockVolume', label: '*Block', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'ukc.blockCoefficient', label: '*BlockCoefficient', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {
    selectRow(index) {
      this.$refs.pptable.unselectRow(index);
      setTimeout(() => {
        this.$refs.pptable.selectRow(index);
      }, 100)
      this.currentRowIndex = index
      // eventBus.$emit('click-wp', index)

    },
    onRowClicked(row, index) {
      window.dispatchEvent(new Event('resize'));
      routes_map.zoomToWaypoint(index, 10, false)
    },
    onRowSelected(items) {
    },
    items() {
      return this.routesStore.getShipWaypoints;
    },
    editCellHandler(data, name) {
      // this.items = this.items1.map(item => ({...item, isEdit: false}));
      this.items[data.index].isEdit = true;
      this.selectedCell = name
    },
    getUpdatedValue(a, b) {
      console.log("update", a, b)
    },
    inputHandler(value, index) {
      console.log("aaa", value, index);
      this.items().forEach((item) => {

        let H = Number(item.minDepth)
        let V = Number(item.ukc.speed)
        let Td = Number(item.ukc.tide)
        let T = Number(item.ukc.draft)
        let Z = Number(item.ukc.zoc)

        item.ukc.squat = item.ukc.isOpenWater ? Number((item.ukc.blockCoefficient * V * V / 100).toFixed(1)) : Number((item.ukc.blockCoefficient * V * V / 50).toFixed(1))
        let Sq = Number(item.ukc.squat)
        item.ukc.ukc = (H + Td - T - Sq).toFixed(2)
        // item.ukc.ukcPolicy = Math.max(3.5, T / 2).toFixed(1)
        // item.ukc.ukcPolicy = item.ukc.isOpenWater ? 3.5 : 0.4
        // item.ukc.zoc = item.ukc.isOpenWater ? Number((4 + 20 * H / 100).toFixed(1)) : Number((0.5 + 1 * H / 100).toFixed(1))
        let UKCP = Number(item.ukc.ukcPolicy)
        item.ukc.safeDepth = (T + Sq + UKCP + Z).toFixed(2)
        let SD = Number(item.ukc.safeDepth)
        item.ukc.isSafe = Number(H + Td - SD) > 0 ? 'YES' : 'NO'
      })

      this.$refs.pptable.refresh();

    },
    calculateUKC() {
      if (this.items()[0].ukc === undefined) {

        let points = [];
        let lat, lon
        this.items().forEach((item, index) => {
          if (index === 0) {
            lat = item.lat
            lon = item.lon
          } else {
            if (index < this.items().length) {
              let middle = midLatlon([lat, lon], [item.lat, item.lon])
              points.push({lat:middle[0], lon:middle[1]});
              lat = item.lat
              lon = item.lon
            }
          }
        })

        console.log('aaa midd', points)

        // let points = [];
        // this.items().forEach((item) => {
        //   points.push({lat:item.lat, lon:item.lon});
        // })

        loadZOC(points).then((zocs) => {

          this.items().forEach((item, index) => {
            item.ukc = {}
            item.ukc.speed = 10
            item.ukc.tide = 0
            item.ukc.isOpenWater = true

            item.ukc.zoc1 = zocs[index]

            let zoc = zocs[index]
            switch (zoc) {
              case 'A1':
                item.ukc.zoc = Number((0.5 + 1 * item.minDepth / 100).toFixed(1))
                break
              case 'A2':
                item.ukc.zoc = Number((1 + 2 * item.minDepth / 100).toFixed(1))
                break
              case 'B':
                item.ukc.zoc = Number((1 + 2 * item.minDepth / 100).toFixed(1))
                break
              case 'C':
                item.ukc.zoc = Number((2 + 5 * item.minDepth / 100).toFixed(1))
                break
              case 'D':
                item.ukc.zoc = Number((2 + 5 * item.minDepth / 100).toFixed(1))
                break
              case 'U':
                item.ukc.zoc = Number((4 + 20 * item.minDepth / 100).toFixed(1))
                break
              default:
                item.ukc.zoc = Number((4 + 20 * item.minDepth / 100).toFixed(1))
                break
            }

            // item.ukc.zoc = Number((4 + 20 * item.minDepth / 100).toFixed(1))
            // item.ukc.zoc = Number((4 + 20 * item.minDepth / 100).toFixed(1))
            // item.ukc.zoc = item.ukc.isOpenWater ? Number((4 + 20 * item.minDepth / 100).toFixed(1)) : Number((0.5 + 1 * item.minDepth / 100).toFixed(1))
            item.ukc.draft = Number(this.routesStore.getShipDraught)
            item.ukc.displacement = Number(this.routesStore.getShipDisplacement)
            item.ukc.blockVolume = Number(this.routesStore.getShipWidth * this.routesStore.getShipLength * item.ukc.draft)
            item.ukc.blockCoefficient = Number((item.ukc.displacement / item.ukc.blockVolume).toFixed(2))
            item.ukc.squat = Number((item.ukc.blockCoefficient * item.ukc.speed * item.ukc.speed / 100).toFixed(1))
            item.ukc.ukc = Number((item.minDepth + item.ukc.tide - item.ukc.draft - item.ukc.squat).toFixed(2))
            // item.ukc.ukcPolicy = item.ukc.isOpenWater ? 3.5 : 0.4
            item.ukc.ukcPolicy = 0.4
            // item.ukc.ukcPolicy = Number(Math.max(3.5, item.ukc.draft / 2).toFixed(1))
            item.ukc.safeDepth = Number((item.ukc.draft + item.ukc.squat + item.ukc.ukcPolicy + item.ukc.zoc).toFixed(2))
            item.ukc.isSafe = Number(item.minDepth + item.ukc.tide - item.ukc.safeDepth) > 0 ? 'YES' : 'NO'
          })
          this.isUKCReady = true
          this.$refs.pptable.refresh();


        }).catch(() => {

        })
      }
    },
    rowClass(item, type) {
      let isSafe = item.ukc.isSafe === 'YES'
      if (!isSafe)
        return 'table-danger'
    },

  }
}
</script>

<style>
.custom-table {

}

.small-black-zoc {
  font-size: 14px;
  font-weight: 400;
  color: black;

  min-width: 50px;

}
.small-black-zoc input, span {
  width: 100% !important;
  padding-left: 4px !important;
  padding-right: 0 !important;
  display:inline-block !important;
}

.custom-table td {
  min-width: 100px;
}

.custom-table td span>input[type="number"]{
  width: 100%;
  margin: 0;
  display:inline-block;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>