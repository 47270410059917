<template>
  <div>
    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col>
            <b-form-select v-model="$parent.$parent.selected_ship" :options="$parent.$parent.ships" :title="getSelectedShipTooltip" v-b-tooltip.hover size="sm"></b-form-select>
          </b-col>
          <b-col class="text-left">
            <b-button variant="info" size="sm" @click="checkUpdates()">Check updates</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-table v-if="deviceStore.getDeliveriesCount > 0"
             ref="deliveriestable"
             class="secondary mt-2"
             fixed
             :busy="isBusy"
             :stacked="is_mobile"
             :items="items_ships()"
             :fields="visible_fields_ships"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             select-mode="single"
             selectable
             show-empty
             responsive
             sort-icon-left
             small
             hover
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>&nbsp;Loading...</strong>
        </div>
      </template>
      <template #cell(url)="row">
        <b-link :href="getDownloadLink(row.item.url)" class="smt17">Download</b-link>
      </template>

      <template #cell(timestamp)="row">
        {{ dayjs(row.item.timestamp).format("DD-MMM-YY HH:mm") }}
      </template>

      <template #cell(order)="row">
        #{{ row.item.order }}
      </template>

      <template #cell(size)="row">
        {{ niceBytes(row.item.size) }}
      </template>

      <template #cell(content)="row">
        <h6>
          <b-badge :variant=getTypeStatus(row.item.type).style>{{ getTypeStatus(row.item.type).name }}</b-badge>&nbsp;
        </h6>
      </template>

      <template #cell(type)="row">
        <h6>
          <b-badge :variant=getTypeStatus(row.item.subType).style>{{ getTypeStatus(row.item.subType).name }} {{getOrderId(row.item) }}</b-badge>
        </h6>
      </template>

    </b-table>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useMomentaryStore} from "@/stores/momentary";
import {useUserStore} from "@/stores/user";
import {loadDeliveries, loadDeliveries1} from "@/helpers/api";
import dayjs from "dayjs";
import {useDeviceStore} from "@/stores/device";

export default {
  name: "Deliveries",
  data() {
    return {
      windowWidth: window.innerWidth,
      sortBy: 'createdAt',
      sortDesc: true,
      sortDirection: 'desc',
      isBusy: false,
      filterOn: [],
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    this.checkUpdates()
  },
  computed: {
    is_mobile() {
      return this.windowWidth <= 760
    },
    ...mapStores(useUserStore, useMomentaryStore, useDeviceStore),
    visible_fields_ships() {
      return [
        {
          key: 'content', label: 'Content', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'type', label: 'Type', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'timestamp', label: 'Date', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'size', label: 'Size', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
        {
          key: 'url', label: 'Download', sortable: true, sortDirection: 'desc',
          tdClass: 'small-black', thClass: 'small-gray'
        },
      ]
    },

  },
  methods: {
    dayjs,
    getDownloadLink(url) {
      return url
    },
    getSelectedShipTooltip() {
      let tooltip = ''
      if (this.$store.state.auth.user.role === 'distributor') {
        let deviceId = this.$parent.$parent.selected_ship;
        let ship = this.$parent.$parent.ships.find(e => e.value === deviceId);
        tooltip = ship?.email + ", " + deviceId;
      }
      return tooltip
    },
    niceBytes(x) {
      const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let l = 0, n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    },
    checkUpdates() {
      if (!this.deviceStore.isDeviceIDExist)
        return

      this.isBusy = true;
      loadDeliveries(this.deviceStore.getDeviceID)
      loadDeliveries1(this.deviceStore.getDeviceID, "2024-12-06T10:16:06.695Z").then((response) => {
        this.isBusy = false;
      })
    },
    items_ships() {
      return this.deviceStore.getDeliveries
    },
    getTypeStatus(status) {
      let val = {}
      switch (status) {
        case 'Full':
          val = {style: "info", name: "full set"}
          break
        case 'Update':
          val = {style: "warning", name: "regular update"}
          break
        case 'NewOrder':
          val = {style: "success", name: "order"}
          break
        default:
          val = {style: "light", name: status}
      }
      return val
    },
  getOrderId(item) {
    let orderId = ''
    if (item.orderId)
      orderId = ': ' + item.orderId

    return orderId
  }

  },
}
</script>

<style>
.position-absolute {
  position: fixed !important;
}
</style>


<style scoped>
.truncate_name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>