import * as authService from '@/services/authService'
import router from '@/router/'
import {file} from 'paths.macro';
import {myAxios} from "@/services/myAxios";
import {eventBus} from "@/main";
import store from "@/store";
import {IS_GETENC} from "@/utils/build-config";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {}, user: null, registration: {}};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {

        // login_by_token({dispatch, commit, rootGetters}, user) {
        //     console.log("login by token")
        //     localStorage.clear();
        //
        //     let username = user.email;
        //     commit('loginRequest', {username});
        //
        //     console.log(file, 'token:' + user.token);
        //
        //     localStorage.setItem('user', JSON.stringify(user));
        //     localStorage.removeItem('selected-device');
        //
        //     commit('loginSuccess', user);
        //     router.push({name: 'devices'});
        // },

        login_by_token({dispatch, commit, rootGetters}, token) {
            localStorage.clear();
            commit('loginRequest', "none");
            authService.login_by_token(token)
                .then(resp => {

                    console.log("ddddddddddddddddd")
                    console.log("ddddddddddddddddd")
                    console.log("ddddddddddddddddd")
                    console.log("ddddddddddddddddd")
                    console.log(resp)
                    console.log(resp.data.user)

                    let user = resp.data.user;
                    if (user.token) {
                        if (process.env.NODE_ENV === "development") {
                            console.log(file, 'token:' + user.token);
                        }
                        localStorage.setItem('user', JSON.stringify(user));
                        // localStorage.removeItem('selected-device');
                    }
                    commit('loginSuccess', user);

                    if (IS_GETENC)
                        router.push({name: 'charts'}).catch(() => {});
                    else
                        router.push({name: 'devices'});
                })
                .catch(error => {
                    commit('clearStatus');
                    commit('loginFailure', error.message);
                    dispatch('alert/error', error.response.data.message, {root: true});
                });
        },


        login({dispatch, commit, rootGetters}, {username, password, prevRoute}) {
            localStorage.clear();
            commit('loginRequest', {username});
            authService.login(username, password)
                .then(resp => {
                    let user = resp.data.user;
                    if (user.token) {
                        if (process.env.NODE_ENV === "development") {
                            console.log(file, 'token:' + user.token);
                        }
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.removeItem('selected-device');
                        commit('loginSuccess', user);
                        if (!prevRoute){
                            if (IS_GETENC)
                                router.push({name: 'charts'}).catch(() => {});
                            else
                                router.push({name: 'devices'});
                        }
                        else {
                            router.push(prevRoute)
                        }

                        if (IS_GETENC)
                            router.push({name: 'charts'}).catch(() => {});
                        else
                            router.push({name: 'devices'});
                    }


                })
                .catch(error => {
                    commit('clearStatus');
                    commit('loginFailure', error.message);
                    dispatch('alert/error', error.response.data.message, {root: true});
                });
        },

        // loginToOrders({dispatch, commit}){
        //     localStorage.clear();
        //     commit('loginRequest', 'placeholder');
        //     authService.loginWithMconnect(mconnectToken).then(resp => {
        //         var user = resp.data.user;
        //         if (user.token) {
        //             if (process.env.NODE_ENV === "development") {
        //                 console.log(file, 'token:' + user.token);
        //             }
        //             localStorage.setItem('user', JSON.stringify(user));
        //             localStorage.removeItem('selected-device');
        //         }
        //
        //         store.commit('current/setMConnectUser', {is_mconnect: true, device_id: user.deviceId});
        //         commit('current')
        //
        //         commit('loginSuccess', user);
        //
        //         router.push({name: 'devices'});
        //     })
        //         .catch(error => {
        //             commit('clearStatus');
        //             commit('loginFailure', error.message);
        //             dispatch('alert/error', error.response.data.message, {root: true});
        //         });
        // },

        loginWithMconnect({dispatch, commit}, mconnectToken){
              localStorage.clear();
              commit('loginRequest', 'placeholder');
            let data = {token: mconnectToken};
            // myAxios.post('api/v1/token', new String(mconnectToken))


              authService.loginWithMconnect(new String(mconnectToken)).then(resp => {
                  console.log("dddd resp", resp)
                  let user = resp.data.user;
                  if (user.token) {
                      if (process.env.NODE_ENV === "development") {
                          console.log(file, 'token:' + user.token);
                      }
                      localStorage.setItem('user', JSON.stringify(user));
                      localStorage.removeItem('selected-device');
                  }

                  // store.commit('current/setMConnectUser', {is_mconnect: true, device_id: user.deviceId});
                  // commit('current')

                  commit('loginSuccess', user);

                  router.push({name: 'deliveries'});
              })
                  .catch(error => {
                      commit('clearStatus');
                      commit('loginFailure', error.message);
                      dispatch('alert/error', error.response.data.message, {root: true});
                  });
        },
        register({dispatch, commit, rootGetters}, {email, password, userType, applicationTypeId}) {
            authService.register(email, password, userType, applicationTypeId)
                .then(resp => {
                    eventBus.$emit('is-registered', true);

                    // if (user.token) {
                    //     if (process.env.NODE_ENV === "development") {
                    //         console.log(file, 'token:' + user.token);
                    //     }
                    //     localStorage.setItem('user', JSON.stringify(user));
                    //     localStorage.removeItem('selected-device');
                    // }
                    // commit('loginSuccess', user);
                    //
                    // router.push({name: 'devices'});
                })
                .catch(error => {
                    eventBus.$emit('is-registered', false);
                    // commit('clearStatus');
                    // commit('loginFailure', error.message);
                    dispatch('alert/error', error.response.data.message, {root: true});
                });
        },
        refreshUser({dispatch, commit, rootGetters}, goto) {
            authService.refreshUser()
                .catch(resp => {
                    commit('clearStatus');
                    router.push({
                        name: 'login'
                    });
                });
        },
        logout({commit, rootGetters}) {
            router.push({name: '/'});

            localStorage.clear();

            authService.logout();
            commit('logout');
        },
    },
    mutations: {
        loginRequest(state, user) {
            state.status = {loggingIn: true};
            state.registration = {};
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = {loggedIn: true};
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;

            //TODO
            localStorage.clear();
            window.location.reload()
        },
        clearStatus(state) {
            state.status = {};
            state.registration = {};
        }
    }
}