import L from "leaflet";
import {pointToSegmentDistance} from "leaflet/src/geometry/LineUtil";
import {eventBus, routes_map} from "@/main";
import * as legs from "@/my-leaf/routes/components/route-legs";
import store from "@/store";

export function getWaypointColor(id = -1) {
    let waypointIndex =  id === -1 ? this.waypoints.length : id

    let safety = 1
    if (this.routesStore.getShipWaypointsCount > 0 && this.routesStore.getShipWaypointsCount > waypointIndex)
        safety = this.routesStore.getShipLegSafety(waypointIndex)

    let color = safety ? 'blue' : 'red'
    return color
}

export function addWP(coordinates, index = -1) {
    // add wp to start/end
    console.log("coordinates", coordinates)

    if (this.waypoints.length > 1 && index === -1) {
        let dist1 = coordinates.distanceTo(this.waypoints.at(0).getLatLng())
        let dist2 = coordinates.distanceTo(this.waypoints.at(-1).getLatLng())

        if (dist1 < dist2)
            index = 0
    }

    // let safety = 1
    // if (this.routesStore.routeResponse.routeLegs.length > 0 && this.routesStore.routeResponse.routeLegs.length > this.waypoints.length)
    //     safety = this.routesStore.routeResponse.routeLegs[this.waypoints.length].safety

    let color = getWaypointColor.call(this)

    let newMarker = new L.circleMarker(coordinates, {
        bubblingMouseEvents: false,
        id: this.waypoints.length,
        autoPan: true,
        radius: 4,
        weight: 15,
        opacity: 0,
        fillOpacity: 0.8,
        color: color,
        isSelected: false,
        wpStatus: 'none'
    }).addTo(this.wpGroup);

    // function trackCursor(evt) {
    //     newMarker.setLatLng(evt.latlng)
    // }
    //
    // newMarker.on("mousedown", () => {
    //     const zoom = this.map.getZoom()
    //     if(zoom >= 14 && this.isShowMapManu) {
    //         this.map.dragging.disable()
    //         this.map.on("mousemove", trackCursor)
    //     }
    // })
    //
    // newMarker.on("mouseup", () => {
    //     this.map.dragging.enable()
    //     this.map.off("mousemove", trackCursor)
    //     legs.clearLegs.call(this)
    //     legs.drawLegs.call(this)
    // })

    if (index < 0) {
        this.waypoints.push(newMarker);
    } else {
        this.waypoints.splice(index, 0, newMarker);
    }

    newMarker.on("click", (e) => {
        if (!this.isShowMapManu) {
            eventBus.$emit('select-weathertable-row', newMarker.options.id)
            eventBus.$emit('select-pptable-row', newMarker.options.id)
            this.zoomToWaypoint(newMarker.options.id, 10, true)
            return
        }


        this.selectWaypoint(newMarker.options.id)
        eventBus.$emit('select-routetable-row', newMarker.options.id)
        eventBus.$emit('show-dangers-in-wp', newMarker.options.id)

        if (this.selecteBaypassWaypoints.length === 0) {
            let id0 = 'start-wp';
            let content = `<button id="` + id0 + `" class="button-13" onclick="">Start</button`
            let popup = L.popup({autoClose: false}).setLatLng(e.latlng).setContent(content).openOn(this.map);
            document.getElementById(id0).addEventListener('click', (e) => {
                this.map.closePopup()
                this.selecteBaypassWaypoints.push(newMarker.options)
                this.drawBaypassStartFinish(newMarker.getLatLng())
            })
        }

        if (this.selecteBaypassWaypoints.length === 1 && this.selecteBaypassWaypoints[0].id !== newMarker.options.id) {
            let id0 = 'finish-wp';
            let content = `<button id="` + id0 + `" class="button-13" onclick="">Finish</button`
            let popup = L.popup({autoClose: false}).setLatLng(e.latlng).setContent(content).openOn(this.map);
            document.getElementById(id0).addEventListener('click', (e) => {
                this.map.closePopup()
                this.selecteBaypassWaypoints.push(newMarker.options)
                this.drawBaypassStartFinish(newMarker.getLatLng())
                this.calculateByPass(this.selecteBaypassWaypoints[0].id, this.selecteBaypassWaypoints[1].id)
                this.selecteBaypassWaypoints = []
            })
        }
    });

    refreshWaypointsTooltips.call(this)

    // this.waypoints.forEach((waypoint, i) => {
    //     waypoint.options.id = i
    //
    //     if (i === 0) {
    //         waypoint.bindTooltip("Start", {permanent: true, direction: 'top', offset: L.point(0, -4)})
    //     } else if (i === this.waypoints.length - 1) {
    //         waypoint.bindTooltip("Finish", {permanent: true, direction: 'top', offset: L.point(0, -4)})
    //     } else {
    //         waypoint.bindTooltip('WP' + i)
    //     }
    // })

    this.refreshControls()
}

export function insertWP(coordinates) {
    if (this.waypoints.length <= 1) {
        addWP.call(this, coordinates)
        return
    }

    let segments = []
    let segmentPointsCount = 0
    let segmentsPoints = [null, null]
    let point = L.Projection.Mercator.project(coordinates);

    for (let i = 0; i < this.waypoints.length; i++) {
        let wp = this.waypoints[i]
        segmentsPoints[segmentPointsCount++] = L.Projection.Mercator.project(wp.getLatLng())
        if (segmentPointsCount === 2) {
            let points = segmentsPoints.slice()
            segments.push(points)
            segmentPointsCount = 0;
            if (i < this.waypoints.length) {
                i--
            }
        }
    }

    let minDistanceIndex = 0
    let minDistanceLeg = -1
    segments.forEach((segment, index) => {
        let dist = pointToSegmentDistance(point, segment[0], segment[1])
        if (minDistanceLeg === -1) {
            minDistanceLeg = dist
        } else {
            if (dist < minDistanceLeg) {
                minDistanceLeg = dist
                minDistanceIndex = index
            }
        }
    })

    addWP.call(this, coordinates, minDistanceIndex + 1)
    this.selecteBaypassWaypoints = []
}

export function removeWP(index) {
    let wpToDelete = this.waypoints[index]
    this.map.removeLayer(wpToDelete)
    this.waypoints.splice(index, 1)

    // this.waypoints.forEach((waypoint, i) => {
    //     waypoint.options.id = i
    //     waypoint.bindTooltip('WP' + i)
    // })

    refreshWaypointsTooltips.call(this)

    // TODO refresh selected
    this.refreshControls()
    this.refreshSelectedWaypointIndex()

    this.selecteBaypassWaypoints = []
    this.map.closePopup()

    legs.clearLegs.call(this)
    legs.drawLegs.call(this)
}

export function drawFromToPoints(coordinates, text) {
    let newMarker = new L.circleMarker(coordinates, {
        bubblingMouseEvents: false,
        autoPan: false,
        radius: 8,
        weight: 1,
        color: '#156df4',
    }).bindTooltip(text, {permanent: true, direction: 'top', offset: L.point(0, -10)})
    newMarker.addTo(this.wpGeneralRouteGroup);
}

export function drawBaypassPoints(coordinates, text) {
    let newMarker = new L.circleMarker(coordinates, {
        bubblingMouseEvents: false,
        autoPan: false,
        radius: 10,
        weight: 2,
        fillOpacity: 0.5,
        color: '#e4a0a8',
    }).bindTooltip(text, {permanent: true, direction: 'top', offset: L.point(0, -10)})
    newMarker.addTo(this.bypassGroup);
}

export function refreshWaypointsTooltips() {
    this.waypoints.forEach((waypoint, i) => {
        waypoint.options.id = i

        if (i === 0) {
            waypoint.bindTooltip("<div style='color:#16ba16;'>Start</div>", {permanent: true, direction: 'top', offset: L.point(0, -4)})
        } else if (i === this.waypoints.length - 1) {
            waypoint.bindTooltip("<div style='color:#eda405;'>Finish</div>", {permanent: true, direction: 'top', offset: L.point(0, -4)})
        } else {
            waypoint.bindTooltip('WP' + i)
        }
    })
}
