import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import router from './router'
import store from './store'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'leaflet/dist/leaflet.css';
import 'photoswipe/dist/photoswipe.css'
import axiosRetry from 'axios-retry';


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

import { library } from '@fortawesome/fontawesome-svg-core'
// import { faChartSimple, faDatabase, faAt, faSearch, faQuestion, faCheck, faStar, faTimes, faCreditCard, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
//import { faApple, faAndroid, faWindows } from '@fortawesome/free-brands-svg-icons'
// import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import ScrollDiv from 'vue-scroll-div';

// import MyDate from './components/MyDate.vue';
import {RouteLeaf} from "@/my-leaf/routes/route-leaf";
// import {TokenLeafS57} from "@/my-leaf/tokens/token-leaf57";
// import {TokenLeafS101} from "@/my-leaf/tokens/token-leaf101";

import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import {ChartsLeaf} from "@/my-leaf/charts/charts-leaf";
import {ShipLeaf} from "@/my-leaf/ships/ship-leaf";

export const eventBus = new Vue()
export const routes_map = new RouteLeaf();
// export const orders_map = new ChartsLeaf();
export const logbooks_map = new ShipLeaf();

Vue.config.productionTip = false

// library.add(faApple, faAndroid, faWindows, faLink, faChartSimple, faAt, faSearch, faQuestion, faCheck, faStar, faTimes, faDatabase, faFileInvoice, faCreditCard)

Vue.component('font-awesome-icon', FontAwesomeIcon)
// Vue.component('my-date', MyDate);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-cookies'))
Vue.use(VueCookies, { expire: '1d'})
Vue.use(ScrollDiv);

// shift pinia init down for vueddevtools
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
Vue.use(PiniaVuePlugin)

new Vue({
    router,
    store,
    pinia,
    render: h => h(App)
}).$mount('#app')
