<template>
  <div>
    <b-table v-if="userStore.isShipsLoaded && this.routesStore.getShipIndex !== -1" style="overflow-x: hidden" sticky-header="520px" size="sm" thead-class="d-none" :items="items()" :fields="visible_fields">
      <template v-slot:cell(value)="row">
        <b-row v-if="row.item.name === 'Draught (m)'">
          <b-col cols="3">{{ curSettings.draught }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="0.2" max="25" v-model="curSettings.draught"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Height (m)'">
          <b-col cols="3">{{ curSettings.height }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="0.2" max="50" v-model="curSettings.height"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Length (m)'">
          <b-col cols="3">{{ curSettings.length }}</b-col>
          <b-col>
            <b-form-input type="range" min="10" step="1" max="200" v-model="curSettings.length"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Width (m)'">
          <b-col cols="3">{{ curSettings.width }}</b-col>
          <b-col>
            <b-form-input type="range" min="5" step="1" max="100" v-model="curSettings.width"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Displacement (t)'">
          <b-col cols="3">{{ curSettings.displacement }}</b-col>
          <b-col>
            <b-form-input type="range" min="100" step="100" max="80000" v-model="curSettings.displacement"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Radius (m)'">
          <b-col cols="3">{{ curSettings.radius }}</b-col>
          <b-col>
            <b-form-input type="range" min="10" step="10" max="500" v-model="curSettings.radius"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'XTE.L (m)'">
          <b-col cols="3">{{ curSettings.xtel }}</b-col>
          <b-col>
            <b-form-input type="range" min="10" step="10" max="3000" v-model="curSettings.xtel"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'XTE.R (m)'">
          <b-col cols="3">{{ curSettings.xter }}</b-col>
          <b-col>
            <b-form-input type="range" min="10" step="10" max="3000" v-model="curSettings.xter"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Speed (knots)'">
          <b-col cols="3">{{ curSettings.speed }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="1" max="50" v-model="curSettings.speed"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Track ratio'">
          <b-col cols="3">{{ curSettings.trackratio }}</b-col>
          <b-col>
            <b-form-input type="range" min="0.1" step="0.1" max="0.9" v-model="curSettings.trackratio"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Shallow water ratio'">
          <b-col cols="3">{{ curSettings.shallowratio }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="0.5" max="10" v-model="curSettings.shallowratio"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Avoid shallow water strength'">
          <b-col cols="3">{{ curSettings.avoidshallowstrength }}</b-col>
          <b-col>
            <b-form-input type="range" min="1" step="0.5" max="10" v-model="curSettings.avoidshallowstrength"/>
          </b-col>
        </b-row>

        <b-row v-if="row.item.name === 'Avoid distance (m)'">
          <b-col cols="3">{{ curSettings.avoiddistance }}</b-col>
          <b-col>
            <b-form-input type="range" min="0" step="10" max="3000" v-model="curSettings.avoiddistance"/>
          </b-col>
        </b-row>

        <b-form-input type="date" v-if="row.item.name === 'Start date'" v-model="curSettings.startdate"/>

        <b-form-input type="time" v-if="row.item.name === 'Start time'" v-model="curSettings.starttime"/>

        <b-form-checkbox v-if="row.item.name === 'Allow Suez canal'" v-model="curSettings.usesuez"/>

        <b-form-checkbox v-if="row.item.name === 'Allow Panama canal'" v-model="curSettings.usepanama"/>

        <b-form-checkbox v-if="row.item.name === 'Allow Bering strait'" v-model="curSettings.usebering"/>

        <b-form-checkbox v-if="row.item.name === 'Allow rivers'" v-model="curSettings.userivers"/>

        <b-form-checkbox v-if="row.item.name === 'Recommended tracks'" v-model="curSettings.userecommendedtracks"/>

        <b-form-checkbox v-if="row.item.name === 'General routes only'" v-model="curSettings.usegeneralroutes"/>
      </template>
    </b-table>

  </div>
</template>

<script>
import {eventBus, routes_map} from "@/main";
import {useRoutesStore} from "@/stores/routes";
import {mapStores} from "pinia";
import {debounce} from 'vue2-debounce'
import dayjs from "dayjs";
import {useUserStore} from "@/stores/user";

export default {
  data() {
    return {
      debounceDepth: {
        isDebounced: false,
      },
      x: ''
    }
  },
  watch: {
    'curSettings.draught'(newVal) {
      if (!this.debounceDepth.isDebounced) {
        this.debounceDepth.isDebounced = true
        debounce(val => this.redrawNoGo(val), 2000)(newVal)
      }
    },
    'curSettings.xtel'() {
      eventBus.$emit('xte-changed')
    },
    'curSettings.xter'() {
      eventBus.$emit('xte-changed')
    },
    'curSettings.startdate'() {
      eventBus.$emit('route-datetime-changed')
    },
    'curSettings.starttime'() {
      eventBus.$emit('route-datetime-changed')
    },
  },
  mounted() {
    // let now = dayjs()
    // this.routesStore.addRouteDate(dayjs(now).format("YYYY-MM-DD"))
    // this.routesStore.addRouteTime(dayjs(now).format("HH:mm:ss"))
  },
  beforeDestroy() {
  },
  computed: {
    ...mapStores(useRoutesStore, useUserStore),
    curIndex(){
      return this.routesStore.getShipIndex
    },
    curSettings(){
      return this.routesStore.routes[this.curIndex]?.routeSettings
    },
    visible_fields() {
      return [
        {key: 'name', label: 'Settings', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'value', label: 'Values', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  methods: {
    items() {
      return [{name: 'Draught (m)', value: this.curSettings?.draught},
        {name: 'Height (m)', value: this.curSettings?.height},
        {name: 'Length (m)', value: this.curSettings?.length},
        {name: 'Width (m)', value: this.curSettings?.width},
        {name: 'Displacement (t)', value: this.curSettings?.displacement},
        {name: 'Radius (m)', value: this.curSettings?.radius},
        {name: 'XTE.L (m)', value: this.curSettings?.xtel},
        {name: 'XTE.R (m)', value: this.curSettings?.xter},
        {name: 'Speed (knots)', value: this.curSettings?.speed},
        {name: 'Track ratio', value: this.curSettings?.trackratio},
        {name: 'Shallow water ratio', value: this.curSettings?.shallowratio},
        {name: 'Avoid shallow water strength', value: this.curSettings?.avoidshallowstrength},
        {name: 'Avoid distance (m)', value: this.curSettings?.avoiddistance},
        {name: 'Start date', value: this.curSettings?.startdate},
        {name: 'Start time', value: this.curSettings?.starttime},
        {name: 'Allow Suez canal', value: this.curSettings?.usesuez},
        {name: 'Allow Panama canal', value: this.curSettings?.usepanama},
        {name: 'Allow Bering strait', value: this.curSettings?.usebering},
        {name: 'Allow rivers', value: this.curSettings?.userivers},
        {name: 'Recommended tracks', value: this.curSettings?.userecommendedtracks},
        {name: 'General routes only', value: this.curSettings?.usegeneralroutes}]

    },
    redrawNoGo(val) {
      this.debounceDepth.isDebounced = false
      routes_map.addNoGoLayer()
    }
  }
}
</script>
