<template>
  <div style="z-index:2;" id="ordersmap" ref="ordersmap" :style="{ height: height + 'px', minHeight: '400px'}"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {ChartsLeaf} from "@/my-leaf/charts/charts-leaf";
import {eventBus} from "@/main";
import {mapStores} from "pinia";
import {useMomentaryStore} from "@/stores/momentary";
import {useRoutesStore} from "@/stores/routes";
import {loadMSSADPByRoute, loadMSSChartsByRoute, loadMSSDataByRoute, loadMSSENPByRoute} from "@/helpers/api";
import {useCatalogsStore} from "@/stores/catalogs";
import {useCartStore} from "@/stores/cart";
import {useDeviceStore} from "@/stores/device";

export default {
  props: ['height', 'showRoute'],
  data() {
    return {
      ordersMap: new ChartsLeaf(),
    }
  },
  // computed: {
  //   windowHeight(){
  //     if (this.$route.name === 'data') return window.innerHeight - 150;
  //     return window.innerHeight - 90;
  //   }
  // },
  mounted() {


    this.ordersMap.init(this.$refs.ordersmap, this.showRoute)

    eventBus.$off('clear-order')
    eventBus.$on('clear-order', () => {
      this.clearOrder()
    })

    eventBus.$off('a1')
    eventBus.$on('a1', (id) => {
      this.ordersMap.onCellClicked(id)
    })

    eventBus.$off('a4')
    eventBus.$on('a4', () => {
      this.ordersMap.dispatchSelectedCells();
    })

    eventBus.$off('a2')
    eventBus.$on('a2', (id) => {
      const m = this.ordersMap.match(id);
      const scope = m.scope;
      const field = m.field;

      this.ordersMap.replaceCell(scope, field, id)
    })

    // let appstate = this.$store.state.charts.profile.appState;
    // console.log(appstate)
    // if (appstate ) {
    //   let lat = appstate.lat
    //   let lon = appstate.lon
    //   let cog = appstate.cog;
    //   let id = appstate.id;
    //   let days = appstate.days;
    //   let aindex = appstate.aindex;
    //   let name = appstate.name;
    //   let route = appstate.route;
    //   let sog = appstate.sog;
    //   let email = appstate.email;
    //   let isActive = appstate.isActive;
    //   let routeJson = appstate.routeJson
    //   this.ordersMap.showAppState(aindex, lat, lon, cog, id, isActive, name, route, sog, email, days, routeJson);
    //
    //   if (this.$store.state.leaflet.map.lat === -1)
    //     this.ordersMap.paneToShip(aindex);
    //   // if (!localStorage.getItem('mapCenter'))
    //   //   this.ordersMap.paneToShip(aindex);
    // }

    // let keeped_route = JSON.parse(localStorage.getItem('route'))
    // console.log("dd", keeped_route)
    // const pts = keeped_route ? JSON.parse(keeped_route)['route_temp_1'] : ""
    // const pts = ""
    // if (pts.length > 1) {
    //   this.ordersMap.add_pMeasure();
    //   this.ordersMap.pMeasure._toggleMeasure();
    //   this.ordersMap.pMeasure._toggleMeasure();
    //   // start line with first point of each polyline
    //   this.ordersMap.pMeasure._startLine(pts[0]);
    //   // add subsequent points:
    //   pts.forEach((point, ind) => {
    //     const latLng = L.latLng(point);
    //     this.ordersMap.pMeasure._mouseMove({latLng});
    //     this.ordersMap.pMeasure._currentLine.addPoint(latLng);
    //     // on last point,
    //     if (ind === pts.length - 1) {
    //       this.ordersMap.pMeasure._finishPolylinePath();
    //       // this.ordersMap.pMeasure._toggleMeasure();
    //     }
    //   });
    // }
    //   window.addEventListener("resize", () => {
    //     if (this.$route.name === 'data') this.windowHeight = window.innerHeight - 150;
    //     else this.windowHeight = window.innerHeight - 90;
    //   });

    //map.init(this.$refs.mm);
    this.ordersMap.showAnotherRoute()
  },
  computed: {
    ...mapStores(useMomentaryStore, useRoutesStore, useCatalogsStore, useCartStore, useDeviceStore),
    currentCartLen() {
      return this.$store.state.current.cart.s63cells.length
    },
  },
  methods: {
    init_map(order_items = []) {
      return new Promise((resolve, reject) => {
        if (this.catalogsStore.getProductsCount > 0) {
          this.ordersMap.drawAllCells(this.catalogsStore.getItems);

          if (order_items.length > 0) {
            this.selectCellByOrder(order_items).then(() => {
              this.cartStore.addAllPublications()
              resolve()
            })
          }

          if (order_items.length === 0 && this.routesStore.getShipWaypointsCount === 0) {
            this.clearOrder()
            this.cartStore.addAllPublications()
            resolve()
          }

          if (order_items.length === 0 && this.routesStore.getShipWaypointsCount > 0) {
            this.cartStore.addAllPublications()
            this.prepareCells().then(() => {
              resolve()
            })
          }

        } else {
          alert("ERROR")
        }
      })
    },

    prepareCells() {
      return new Promise(resolve => {
        let points = []
        this.routesStore.getShipWaypoints.forEach(item => {
          points.push({lat: item.lat, lon: item.lon})
        })

        //TODO Primar route

        if (this.deviceStore.getAgreementProvider === 'UKHO') {
          loadMSSDataByRoute(points).then(() => {
            if (this.currentCartLen === 0) {
              this.selectCellByRoute().then(() => {
                resolve()
              })
            } else {
              resolve()
            }
          })
        } else if (this.deviceStore.getAgreementProvider === 'Primar') {
          loadMSSChartsByRoute(points).then(() => {
            if (this.currentCartLen === 0) {
              this.selectCellByRoute().then(() => {
                resolve()
              })
            } else {
              resolve()
            }
          })
        }

        // loadMSSChartsByRoute(points).then(() => {
        //   loadMSSADPByRoute(points)
        //   loadMSSENPByRoute(points)
        //   if (this.currentCartLen === 0) {
        //     this.selectCellByRoute().then(() => {
        //       resolve()
        //     })
        //   } else {
        //     resolve()
        //   }
        // })
      })
    },

    selectCellByOrder(items) {
      console.log("aaaa O2", items)
      return new Promise(resolve => {
        if (items.length > 0) {
          items.forEach((item) => {
            console.log("aaaa O3", item.trim())
            this.ordersMap.onCellClicked(item.trim());
          });
          this.ordersMap.dispatchSelectedCells();
        }
        resolve()
      })
    },

    selectCellByRoute() {
      return new Promise(resolve => {
        if (this.momentaryStore.route.chartsbyroute.length > 0) {
          this.momentaryStore.route.chartsbyroute.forEach((item) => {
            this.ordersMap.onCellClicked(item.trim());
          });
          this.ordersMap.dispatchSelectedCells();
          this.momentaryStore.clearChartsByRoute()

        }
        resolve()
      })
    },

    clearOrder() {
      this.cartStore.clear()
      this.ordersMap.clearSelectedCells()
    }
  }
}
;
</script>

<style>
#ordersmap {
  border: solid 1px dimgray;
}

.leaflet-interactive:hover {
  stroke: #ffa011;
  stroke-width: 4;
}


</style>