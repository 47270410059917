<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mt-4">
      <div v-if=!url>
<!--        <b-alert variant="info" show>-->
<!--          <h3>Placing your order, please wait...</h3><br>-->
<!--          <b-spinner variant="warning"></b-spinner>-->
<!--        </b-alert>-->
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>&nbsp;Placing your order...</strong>
        </div>
      </div>
      <div v-else>
        <b-alert variant="info" show>
          <h3><a :href=url>Click to proceed to payment</a></h3>
        </b-alert>
      </div>
    </div>
    <h1>

    </h1>
  </div>
</template>

<script>
import MySpinner from "@/components/MySpinner";
import {eventBus} from "@/main";

export default {
  name: "Loader",
  data() {
    return {
      url: ''
    }
  },
  mounted() {
    eventBus.$on('get-stripe-url', (url) => {
      this.url = url
    })
  }
}
</script>