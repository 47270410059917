import {myAxios} from "@/services/myAxios";
import {file} from 'paths.macro';
import {dumpFile, getS63CKTInfo} from "@/utils/utils";
import {eventBus} from "@/main";
import store from "@/store";
// import {commit} from "lodash/seq";
import {CURRENT_PRODUCT, DEBUG_LOCAL_RESPONSE, NAVICOM} from "@/utils/build-config";
import {ActiveTab} from "@/utils/config";
// import resp from "@/data/primar_countries.json";

const initialState = {
    s63: {
        primar_map_info: [],
        products: {
            all: [],
            multiPart: {},
            linksToProducts: {},
            allS102: [],
            multiPartS102: {},
            linksToProductsS102: {}
        },
        countries: []
    },
}

export const charts = {
    // namespaced: true,
    state: initialState,
    getters: {
        cellById: (state) => (id) => {
            return state.s63.primar_map_info.find(chart => chart.id === id)
        },
    }

    ,
    actions: {
        getENCs_Primar({commit}) {
            let url = "api/v1/primar/enc/";
            return new Promise((resolve, reject) => {

                fetch('./primar_enc.json')
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)
                        resolve(json)
                    });
            })
        },
        getENCs_UKHO({commit}) {
            let url = "api/v1/primar/enc/";
            return new Promise((resolve, reject) => {

                fetch('./ukho_enc.json')
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)
                        resolve(json)
                    });
            })
        },
        getENCCountries_Primar({commit}) {
            commit("current/pushLoadingMarker", "charts|getENCCountries")
            let url = "api/v1/primar/countries/";
            return new Promise(resolve => {

                fetch('./primar_countries.json')
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)
                        commit("setS63Countries", Object.freeze(json))
                        resolve()
                        return
                    });
            })
        },
        getENCProducts_Primar({commit, dispatch}) {
            commit("current/pushLoadingMarker", "charts|getENCProducts")

            let url = "api/v1/primar/products/";
            return new Promise(resolve => {

                fetch('./primar_products.json')
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)

                let items = {};
                let itemsS102 = {};
                let links = {};
                let linksS102 = {};
                // TODO fix offset 7, 2

                let indexENC;
                let indexS102;
                json.productTypes.forEach((element, i) => {
                    console.log(i, element.id)

                    if (element.id === 1)
                        indexENC = i;

                    if (element.id === 11)
                        indexS102 = i;
                });

                let groups = json.productTypes.at(indexENC).productList.products;
                let groupsS102 = CURRENT_PRODUCT.is_navicom ? [] : json.productTypes.at(indexS102).productList.products;

                groups.forEach(x => {
                    if (x.productCellList) {
                        if (x.productCellList.productCells.length > 1) {
                            items[x.id] = x;
                        }
                        x.productCellList.productCells.forEach(y => {
                            links[y.id] = x.id
                        })
                    }
                })

                groupsS102.forEach(x => {
                    if (x.productDatasetList) {
                        if (x.productDatasetList.productDatasets.length > 1) {
                            itemsS102[x.id] = x;
                        }
                        x.productDatasetList.productDatasets.forEach(y => {
                            linksS102[y.id] = x.id
                        })
                    }
                })
                Object.keys(itemsS102).forEach(x => {
                    items[x] = itemsS102[x]
                })
                Object.keys(linksS102).forEach(x => {
                    links[x] = linksS102[x]
                })

                commit("setS63ProductsMultipart", Object.freeze(items));
                commit("setLinksToProducts", Object.freeze(links));
                commit("setAllProducts", Object.freeze(groups));
                commit("setS63ProductsMultipartS102", Object.freeze(itemsS102));
                commit("setLinksToProductsS102", Object.freeze(linksS102));
                commit("setAllProductsS102", Object.freeze(groupsS102));

                console.log('get products: end')
                commit("current/popLoadingMarker", "charts|getENCProducts")

                resolve()
                    });
                return
                // ----------------------------------------------


                myAxios.get(url).then(resp => {
                    let items = {};
                    let itemsS102 = {};
                    let links = {};
                    let linksS102 = {};
                    // TODO fix offset 7, 2

                    let indexENC;
                    let indexS102;
                    resp.data.productTypes.forEach((element, i) => {
                        console.log(i, element.id)

                        if (element.id === 1)
                            indexENC = i;

                        if (element.id === 11)
                            indexS102 = i;
                    });

                    let groups = resp.data.productTypes.at(indexENC).productList.products;
                    let groupsS102 = CURRENT_PRODUCT.is_navicom ? [] : resp.data.productTypes.at(indexS102).productList.products;

                    groups.forEach(x => {
                        if (x.productCellList) {
                            if (x.productCellList.productCells.length > 1) {
                                items[x.id] = x;
                            }
                            x.productCellList.productCells.forEach(y => {
                                links[y.id] = x.id
                            })
                        }
                    })

                    groupsS102.forEach(x => {
                        if (x.productDatasetList) {
                            if (x.productDatasetList.productDatasets.length > 1) {
                                itemsS102[x.id] = x;
                            }
                            x.productDatasetList.productDatasets.forEach(y => {
                                linksS102[y.id] = x.id
                            })
                        }
                    })
                    Object.keys(itemsS102).forEach(x => {
                        items[x] = itemsS102[x]
                    })
                    Object.keys(linksS102).forEach(x => {
                        links[x] = linksS102[x]
                    })

                    commit("setS63ProductsMultipart", Object.freeze(items));
                    commit("setLinksToProducts", Object.freeze(links));
                    commit("setAllProducts", Object.freeze(groups));
                    commit("setS63ProductsMultipartS102", Object.freeze(itemsS102));
                    commit("setLinksToProductsS102", Object.freeze(linksS102));
                    commit("setAllProductsS102", Object.freeze(groupsS102));

                    console.log('get products: end')
                    commit("current/popLoadingMarker", "charts|getENCProducts")
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    eventBus.$emit('network-error', 103)
                });
            })
        },
        getENCProducts_UKHO({commit, dispatch}) {
            commit("current/pushLoadingMarker", "charts|getENCProducts")

            let url = "api/v1/primar/products/";
            return new Promise(resolve => {

                fetch('./ukho_products.json')
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)

                let items = {};
                let itemsS102 = {};
                let links = {};
                let linksS102 = {};
                // TODO fix offset 7, 2

                let indexENC;
                json.productTypes.forEach((element, i) => {
                    console.log(i, element.id)

                    if (element.id === 1)
                        indexENC = i;
                });

                indexENC = 0;

                let groups = json.productTypes.at(indexENC).productList.products;
                console.log("1212", json)
                console.log("1212", groups)
                // let groupsS102 = CURRENT_PRODUCT.is_navicom ? [] : json.productTypes.at(indexS102).productList.products;
                let groupsS102 = []

                groups.forEach(x => {
                    if (x.productCellList) {
                        if (x.productCellList.productCells.length > 1) {
                            items[x.id] = x;
                        }
                        x.productCellList.productCells.forEach(y => {
                            links[y.id] = x.id
                        })
                    }
                })

                commit("setS63ProductsMultipart", Object.freeze(items));
                commit("setLinksToProducts", Object.freeze(links));
                commit("setAllProducts", Object.freeze(groups));
                commit("setS63ProductsMultipartS102", Object.freeze(itemsS102));
                commit("setLinksToProductsS102", Object.freeze(linksS102));
                commit("setAllProductsS102", Object.freeze(groupsS102));

                console.log('get products: end')
                commit("current/popLoadingMarker", "charts|getENCProducts")

                resolve()
                    });
                return
                // ----------------------------------------------


                myAxios.get(url).then(resp => {
                    let items = {};
                    let itemsS102 = {};
                    let links = {};
                    let linksS102 = {};
                    // TODO fix offset 7, 2

                    let indexENC;
                    let indexS102;
                    resp.data.productTypes.forEach((element, i) => {
                        console.log(i, element.id)

                        if (element.id === 1)
                            indexENC = i;

                        if (element.id === 11)
                            indexS102 = i;
                    });

                    let groups = resp.data.productTypes.at(indexENC).productList.products;
                    let groupsS102 = CURRENT_PRODUCT.is_navicom ? [] : resp.data.productTypes.at(indexS102).productList.products;

                    groups.forEach(x => {
                        if (x.productCellList) {
                            if (x.productCellList.productCells.length > 1) {
                                items[x.id] = x;
                            }
                            x.productCellList.productCells.forEach(y => {
                                links[y.id] = x.id
                            })
                        }
                    })

                    groupsS102.forEach(x => {
                        if (x.productDatasetList) {
                            if (x.productDatasetList.productDatasets.length > 1) {
                                itemsS102[x.id] = x;
                            }
                            x.productDatasetList.productDatasets.forEach(y => {
                                linksS102[y.id] = x.id
                            })
                        }
                    })
                    Object.keys(itemsS102).forEach(x => {
                        items[x] = itemsS102[x]
                    })
                    Object.keys(linksS102).forEach(x => {
                        links[x] = linksS102[x]
                    })

                    commit("setS63ProductsMultipart", Object.freeze(items));
                    commit("setLinksToProducts", Object.freeze(links));
                    commit("setAllProducts", Object.freeze(groups));
                    commit("setS63ProductsMultipartS102", Object.freeze(itemsS102));
                    commit("setLinksToProductsS102", Object.freeze(linksS102));
                    commit("setAllProductsS102", Object.freeze(groupsS102));

                    console.log('get products: end')
                    commit("current/popLoadingMarker", "charts|getENCProducts")
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    eventBus.$emit('network-error', 103)
                });
            })
        },
        addS63Items({commit, dispatch}, keysList){
            console.log(file, "add63Items : start")
            console.log(keysList)
            return new Promise( resolve => {
                const allItems = charts.state.s63.products.all;
                const allItemsS102 = charts.state.s63.products.allS102;

                let newItems = [];
                // commit("setS63Items", newItems);// dated
                keysList.forEach( key => {

                    let item = allItems.find( obj => {return obj.id == key;})
                    if (key.startsWith("102")) {
                        item = allItemsS102.find( obj => {return obj.id == key;})
                    }

                    if (!item) {
                        console.log(key)}
                    if (!newItems.find(obj => {return obj.id === key;})) {
                        newItems.push(item);
                    }
                })
                // commit("setS63Items", newItems); // dated
                let CartItems = [];
                newItems.forEach(item => {
                    const countryInfo = charts.state.s63.countries.find(x => x.id === item.countryId);
                    let subTypes
                    if (countryInfo) {
                        subTypes = countryInfo.possibleSubscriptionTypeList.possibleSubscriptionTypes
                    }
                    else {
                        //TODO
                        subTypes = [{"id": 4}];
                    }

                    let periods = []
                    subTypes.forEach( x => {
                        switch (x.id) {
                            case 0: periods.push(12); break;
                            case 1: periods.push(6); break;
                            case 4: periods.push(3); break;
                            default: break;
                        }
                    })
                    console.log(periods)

                    console.log(item)

                    CartItems.push(
                        {
                            id: item.id,
                            name: item.title,
                            cells: (item.id.startsWith("102") ? item.productDatasetList.productDatasets : item.productCellList.productCells),
                            periods: periods,
                            disabled: false
                        }
                    )
                })
                dispatch("current/addS63Cart", CartItems)
                    .then( () => {
                        console.log(file, "add63Items : end")
                        resolve();
                    })
            })
        },
        addItems({commit}, payload) {
            let items = payload["items"];
            let storage = payload["storage"];

            return new Promise(resolve => {
                    let selectedCharts = storage.items.filter(function (e) {
                        return e.is_included === true;
                    });
                    commit("excludeAllItems", selectedCharts);
                    items.forEach(function (item, i, arr) {
                        let chartsSet = storage.items.filter(function (e) {
                            return e.name === item;
                        });
                        commit("includeItems", chartsSet);
                        resolve()
                    });
                }
            )
        },
    },
    mutations: {
        setS63Countries(state, payload) {
            state.s63.countries = payload;
        },
        setAllProducts(state, payload){
            state.s63.products.all = payload;
        },
        setAllProductsS102(state, payload){
            state.s63.products.allS102 = payload;
        },
        setLinksToProducts(state, payload){
            state.s63.products.linksToProducts = payload;
        },
        setLinksToProductsS102(state, payload){
            state.s63.products.linksToProductsS102 = payload;
        },
        setS63ProductsMultipart(state, payload){
            state.s63.products.multiPart = payload;
        },
        setS63ProductsMultipartS102(state, payload){
            state.s63.products.multiPartS102 = payload;
        },
        excludeAllItems(state, selectedItems) {
            for (let index in selectedItems) {
                selectedItems.at(index).is_included = false;
            }
        },
        includeItems(state, items) {
            items.at(0).is_included = true;
        },
        addPrimarMapInfos(state, PMI) {
            state.s63.primar_map_info = PMI;
        },
    }
}